import React, { useState } from "react";
import PageStructure from "../../../components/PageStructure";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import BlogLine from "../../../components/BlogLine";
import GeneralTitle from "../../../components/GeneralTitle";
import SingularTabs from "../../../components/SingularTabs";

const EnrichmentSports = () => {



    const [tabs, setTabs] = useState([{ id: 0, name: "Preparatory", active: true }, { id: 1, name: "Foundation", active: false }, { id: 2, name: "Middle", active: false }]);


    return (
        <div className="general_page">

            <div className="title bold-text secondary-text font22 mt30">

                <GeneralTitle title={"Well being in Action"} />
            </div>
            <PageStructure>
                <div className="left_side page_content">

                    <div className="main_heading_intro mb50 font16 desc primary-text">
                        In the elective "Well-Being in Action," students embark on a journey of self-discovery and holistic development. This course transcends traditional academic boundaries to explore the multifaceted aspects of well-being, encompassing physical health, mental resilience, emotional intelligence, and social connections. Through a combination of practical exercises, mindfulness activities, and discussions, students not only learn about the importance of well-being but actively apply strategies to enhance their daily lives. From cultivating healthy habits to managing stress and fostering positive relationships, "Well-Being in Action" empowers students to take charge of their physical and mental health. By embracing a proactive approach to well-being, students not only enhance their personal lives but also contribute to creating a positive and supportive community within the school environment. This elective serves as a compass, guiding students toward a more balanced and fulfilling life
                    </div>

                    <div className="card">
                        <div className="title primary-text font16">
                            Indoor gaming and recreation
                        </div>
                        <div className="desc font13 ">
                            <div className="title font12 primary-text">The school possess infrastructure and equipment as the following allow indoor gaming:</div>
                            <ul style={{ marginLeft: 30 }}>

                                <li>
                                    An indoor gym consisting of a multiplay station, soft blocks, twin tunnel, play tunnel, titter totter, slides, junior hurdle, pony ride, soft animals, trampoline and such like.
                                </li>
                                <li>
                                    Board games, blocks, lego, clay doughs, indoor floor footballs, indoor basket ball for toddlers, puppet theatre, etc. for lower primary and primary grades.
                                </li>
                                <li>
                                    An equipped library with over 3000 children’s books and reference material
                                </li>


                            </ul>
                        </div>
                    </div>

                    {/* <div className="mt30">
                        <GeneralTitle center font={"font18"} title={"Sports and sportsmanship – we aim to encourage both in our students"} />

                    </div>



                    <div className="title font20 mt30 secondary-text">
                        Sports at Dolphin
                    </div>

                    <div className="desc font12">
                        Sports teams at Dolphin primarily participate in a local sports conference called Bangalore Athletics and Activities Schools Conference (BAASC). Our middle and high school teams also compete in tournaments in India and our high school teams compete internationally.

                        The BAASC seasons are organised as set out below. During season one our middle school basketball basketball teams also compete in the International Schools Athletics Conference of India (ISACI) with other international schools from Delhi, Hyderabad and Pune. During season two our high school football teams play at a tournament in Thailand with many other schools from across Asia. During season three our middle school football teams, and our middle and high school swim teams, again compete in ISACI tournaments with other international schools from across India.
                    </div> */}

                    {/* <SingularTabs tabs={tabs} /> */}


                    {/* {tabs[0].active ? <SportsActivityPrepatory /> : null}
                    {tabs[1].active ? <SportsActivityFoundation /> : null}
                    {tabs[2].active ? <SportsActivityMiddle /> : null} */}





                </div>
                <div className="right_side">
                    <BlogLine size={2} />
                </div>



            </PageStructure>

            <AcademicPartners />


        </div>



    )

}



const SportsActivityPrepatory = () => {
    return (
        <div className="card secondary-card">
            <div className="title secondary-text font16">
                SEASON 1
            </div>
            <div className="desc font12">
                <p>Under 11- Boys & Girls Football</p>
                <p>Under 11- Boys & Girls Tennis</p>
            </div>
            <div className="title secondary-text font16">
                SEASON 2
            </div>
            <div className="desc font12">
                <p>Under 9- Co-ed Football</p>
                <p>Under 11- Boys & Girls Basketball</p>
            </div>
            <div className="title secondary-text font16">
                SEASON 3
            </div>

            <div className="desc font12">
                <p>Under 9 & 11- Boys & Girls Swimming</p>
                <p> Under 11- Boys & Girls Badminton </p>
            </div>
        </div>
    )
}

const SportsActivityFoundation = () => {
    return (
        <div className="card secondary-card">
            <div className="title secondary-text font16">
                SEASON 1
            </div>


            <div className="desc font12">
                <p>Boys & Girls Basketball</p>
                <p>Boys & Girls Badminton</p>
            </div>
            <div className="title secondary-text font16">
                SEASON 2
            </div>
            <div className="desc font12">
                <p>Cross Country</p>
                <p>Boys & Girls Swimming</p>
            </div>
            <div className="title secondary-text font16">
                SEASON 3
            </div>

            <div className="desc font12">
                <p>Boys & Girls Footbal</p>
            </div>
        </div>
    )
}

const SportsActivityMiddle = () => {
    return (
        <div className="card secondary-card">
            <div className="title secondary-text font16">
                SEASON 1
            </div>
            <div className="desc font12">
                <p>Boys & Girls Footbal</p>
            </div>
            <div className="title secondary-text font16">
                SEASON 2
            </div>
            <div className="desc font12">
                <p>Boys & Girls Basketball</p>
                <p>Boys & Girls Swimming</p>
                <p>Cross Country</p>
            </div>
            <div className="title secondary-text font16">
                SEASON 3
            </div>

            <div className="desc font12">
                <p>Boys & Girls Volleybal</p>
            </div>
        </div>
    )
}

export default EnrichmentSports;