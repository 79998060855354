import React from "react";
import styles from "./kupos-checkbox2.module.less";

const MyCheckbox = ({ checked = false, label = "", onClick }) => {
  return (
    <div className={"checkbox_wrapper"}>
      <div className={"_checkbox"} onClick={onClick}>
        {checked && <div className={"_checkbox_dot"}></div>}
      </div>
      <p className={"_label"}>{label}</p>
    </div>
  );
};

export default MyCheckbox;
