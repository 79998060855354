import React from "react";
import { useRecoilValue } from "recoil";
import { loadingState } from "../../Recoil/atoms";
const MyButton = (props) => {
  const loading = useRecoilValue(loadingState)
  return (
    <div className="button_wrapper">
      {loading ? <span className="loader"></span> :
        <div
          className={"product_button"}
          onClick={props.onClick}
          style={props.style ? props?.style : null}
        >
          {props.src ? (
            <img src={props.src} width={20} height={20} alt="" />
          ) : null}


          <span
            className={props.fontClass ? props.fontClass : "font10"}
            style={props.fontStyle}
          >
            {props.label}
          </span>
        </div>}
    </div>
  );
};

export default MyButton;
