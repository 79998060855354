import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ContentContainer from "../../components/ContentContainer";
import SplashTitle from "../../components/SplashTiltle";
import AcademicPartners from "../../components/page-components/AcademicPartners";
import DolphinCard from "../../components/DolphinCard";
import { useRecoilState } from "recoil";
import { aboutPageTabsState } from "../../Recoil/atoms";
import GeneralTitle from "../../components/GeneralTitle";

const AboutSkuastK = () => {
  const [aboutTabs, setAboutTabs] = useRecoilState(aboutPageTabsState);
  const [sections, setSections] = useState([
    {
      id: 0,
      image: "/images/admissions/enquire.jpeg",
      title: "Enquire",
      desc:
        "Founded in 2008, Dolphin is proud to be an accredited and authorised IB World School. We are dedicated to our mission and vision, and our Strategic Plan for 2020 - 2024.",
      active: true,
      link: "admissions/enquire"
    },
    {
      id: 1,
      image: "/images/admissions/registration.jpeg",
      title: "Registration",
      desc:
        "Dolphin is a not-for-profit school and is governed by a five-member, appointed Governing Council consisting of prominent business leaders, former parents, and the founder’s representative. The Governing Council acts under the delegated authority of the Board of Dolphin Education Foundation. ",
      active: false,
      link: "admissions/apply"
    },
  ]);

  useEffect(() => {
    loadAboutCMS();
    window.scrollTo(0, 0);
  }, []);

  const loadAboutCMS = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/cms/about",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          // setAboutTabsData(result.data.data);
        }
      },
    });
  };

  return (
    <div className="aboutskuast-k-container">
      <HeroBanner name="Admissions" />

      <ContentContainer>
        <div className="about_container">
          <GeneralTitle title={"Admissions"} />
          <div className="brief_intro card secondary-card font12">
            <p>
              Welcome to Dolphin International School, Pulwama – a leading institution in the picturesque valley. At Dolphin, we take pride in catering to the diverse needs of students from various backgrounds. Our commitment is to create an environment that fosters learning, growth, and holistic development. Embracing the future, we employ the latest technology to deliver education that aligns with global standards. Dolphin is not just a place of learning; it's an institution that provides students with international exposure, preparing them for a world where knowledge knows no borders.
            </p>
            <p className="text-center">
              Join us at Dolphin, where education meets innovation, and every child is equipped for a journey of boundless growth.
            </p>
          </div>

          <div className="more_sections_title">

            <GeneralTitle title={"In this section"} />
          </div>

          <div className="other_sections">
            {sections?.map((section, index) => <DolphinCard hideDescription item={section} index={index} tabs={aboutTabs} setTabs={setAboutTabs} />
            )}
          </div>

          <AcademicPartners />
        </div>
      </ContentContainer>
    </div>
  );
};
export default AboutSkuastK;
