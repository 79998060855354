import React, { useEffect, useState } from "react";
import HeroBanner from "../../components/HeroBanner";
import ContentContainer from "../../components/ContentContainer";
import AcademicPartners from "../../components/page-components/AcademicPartners";
import { useRecoilState, useSetRecoilState } from "recoil";
import { blogsState, currentBlogState } from "../../Recoil/atoms";
import PageStructure from "../../components/PageStructure";
import BlogLine from "../../components/BlogLine";
import { useHistory } from "react-router-dom";
import AppData from "../../AppData";
import { callApi } from "../../api/callApi";
import { DateUtils } from "rsuite/esm/utils";

const Blogs = () => {
  const router = useHistory();
  const setCurrentBlog = useSetRecoilState(currentBlogState);
  const [blogs, setBlogs] = useRecoilState(blogsState);

  useEffect(() => {
    window.scrollTo(0, 0);

    let blogsLocal = [];

    callApi({
      url: AppData.BASE_URL + "api/v1/blogs",
      method: "GET",
      callback: (result) => {
        if (result?.data?.data?.length) {
          result?.data?.data?.map((blog) => {
            blogsLocal.push({
              id: blog.id,
              image:
                AppData.BASE_URL + result.data.mediaPath + "/" + blog.image,
              title: blog.title,
              desc: blog.description,
              author: blog.user?.name,
              date: blog.created_at.split("T")[0],
            });
          });

          setBlogs(blogsLocal);
        }
      },
    });
  }, []);

  return (
    <div className="blogs_wrapper">
      <HeroBanner name="Latest events" />

      <ContentContainer>
        <div className="title font16 bold-text secondary-text font22 mt40">
          Latest events
        </div>
        {/* <PageStructure> */}
        <div className="left_side news_wrapper">
          {blogs?.map((blog, key) => (
            <BlogItem
              blog={blog}
              key={key}
              router={router}
              setBlog={setCurrentBlog}
            />
          ))}
        </div>
        {/* <div className="right_side">
            <BlogLine />
          </div> */}
        {/* </PageStructure> */}
        <AcademicPartners />
      </ContentContainer>
    </div>
  );
};

const BlogItem = ({ blog, key, router, setBlog }) => {
  const gotoBlog = (blog) => {
    setBlog(blog);
    router.push(`/blogs/${blog.id}`);
  };
  return (
    <div key={key} className="blog_item" onClick={() => gotoBlog(blog)}>
      <div className="image">
        <img src={blog.image} alt="image" />
      </div>
      <div className="title">{blog.title}</div>
      {/* <div className="author">{blog.author}</div> */}
      <div className="date font14">
        {new Date(blog.date).toLocaleDateString()}
      </div>
      <div
        className="description font14"
        dangerouslySetInnerHTML={{ __html: blog.desc }}
      ></div>
    </div>
  );
};

export default Blogs;
