import React, { useState, useEffect } from "react";
import MultilevelDropdown from "./MultilevelDropdown";
import { useHistory } from "react-router-dom";
// import MobileHeader from "./MobileHeader";
import MobileHeaderTwo from "./MobileHeaderTwo";

function Header(props) {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 786);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 786);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      removeEventListener("resize", handleResize)
    }

  }, [])

  return (
    <div className="header-parent">
      <div className="logo-conntainer" onClick={() => history.push("/")}>
        <img src="/images/logo.png" alt="logo" />
      </div>
      <div className="links-container font15">
        {isMobile ? <MobileHeaderTwo /> : <MultilevelDropdown />}
      </div>
    </div>
  );
}

export default Header;
