import React from "react";
import PageStructure from "../../../components/PageStructure";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import BlogLine from "../../../components/BlogLine";
import GeneralTitle from "../../../components/GeneralTitle";

const EnrichmentArts = () => {


    return (
        <div className="general_page">

            {/* <div className="title bold-text secondary-text font22">Minds in Motion</div> */}
            <div className="mt30">
                <GeneralTitle title={"Being the change by design"} />
            </div>
            <PageStructure>
                <div className="left_side page_content">

                    <div className="main_heading_intro mb50 font16 desc primary-text">
                        This theme is aimed at making minor shifts in choice which impact people and spaces around. The objective is to help students take charge of a situation that deeply affects them and work towards changing it by design. Students understand the power of design to drive change and get engage in an immersive process of making an innovative idea come to life as a product / service that responds to a pressing need around them.
                    </div>

                    {/* <React.Fragment >
                        <div className="title font18 bold-text secondary-text">Art in Primary School</div>


                        <div class="top_intro_cards enrichment_cards">

                            <div class="card card-secondary white-text secondary-background">
                                The Arts team consists of visual artists and performing artists. Both Arts are a part of the transdisciplinary programme of learning, making authentic connections to units of inquiry and stand alone units when the connection is not significant or relevant. How we express ourselves is an obvious authentic connection where cross-campus teams collaboratively develop learning. Authentic connections can also be made to more obscure themes like where we are in place and time.
                            </div>
                            <div class="card white-text primary-background">
                                The Arts programme includes exploring ideas and improvising with ways to represent ideas, responding to and interpreting the Arts, developing skills and processes, and sharing the Arts through performance, presentation, or display for an audience. At Dolphin, the three dimensions namely, knowledge, skills, and enduring understandings are given equal emphasis. Learners are immersed in learning that is transferable, and work they can be proud of that has individual flair and a stamp of the personal.
                            </div>

                        </div>


                    </React.Fragment> */}

                    {/* <div className="faculty_spotlight mt40 outline">
                        <img src="/images/hero/about_main_page.jpeg" alt="faculty" />

                        <div className="more_details pd20">

                            <div className="title primary-text font16">
                                The Art Center
                            </div>

                            <div className=" general-text font12 text-justify desc">
                                Our purpose-built facility enhances the programme by providing a space that is aesthetically pleasing, spacious and functional. The Arts centre has dedicated learning spaces for Visual Arts, Drama & Music.
                                <br />
                                Opportunity for learners to present and curate abound. They include class, grade and Primary School wide options. Performances such as a poetry slam, fortnightly community times, art exhibitions, dramatic performances, a talent show, choirs and classroom singing all add up to a programme that has meaning and is enthusiastically supported by the community.
                            </div>
                        </div>

                    </div> */}

                    {/* <div className="main_heading_intro mb50 mt40 font22 desc primary-text">
                        Dolphin prides itself on a committed Arts department with a special focus on Visual Art and Performing Arts (Music, Drama, and Dance). The transdisciplinary nature of Arts gives them relevance throughout the International Baccalaureate curriculum.
                    </div> */}

                    {/* <React.Fragment >
                        <div className="title font18 bold-text secondary-text">Art in Secondary School</div>


                        <div class="top_intro_cards enrichment_cards">

                            <div class="card card-secondary white-text secondary-background">
                                The Arts team consists of visual artists and performing artists. Both Arts are a part of the transdisciplinary programme of learning, making authentic connections to units of inquiry and stand alone units when the connection is not significant or relevant. How we express ourselves is an obvious authentic connection where cross-campus teams collaboratively develop learning. Authentic connections can also be made to more obscure themes like where we are in place and time.
                            </div>
                            <div class="card white-text primary-background">
                                The Arts programme includes exploring ideas and improvising with ways to represent ideas, responding to and interpreting the Arts, developing skills and processes, and sharing the Arts through performance, presentation, or display for an audience. At Dolphin, the three dimensions namely, knowledge, skills, and enduring understandings are given equal emphasis. Learners are immersed in learning that is transferable, and work they can be proud of that has individual flair and a stamp of the personal.
                            </div>

                        </div>


                    </React.Fragment> */}

                    <div className="card mt40">
                        <div className="title primary-text font16">
                            KIDS EDUCATION REVOLUTION(KER)
                        </div>

                        <div className="desc font12">
                            At KER, we aim to create a movement of students and educators who reimagine education together. Believe that this movement will lead us to our vision which is - 'All kids will unleash their potential for a reimagined world.’
                            KER is a platform for educators and students to explore and experiment with 3 Principles and a set of skills called the 8Cs which are as below respectively:

                            <div style={{ marginLeft: 30 }} className="title">3 core Principles</div>
                            <ul style={{ marginLeft: 50 }}>
                                <li>
                                    Safe Space for Voice: We believe that a safe space is a place where kids can be who they are and say what they believe. Equally, it is a space where they practice listening to what others say without judgment and valuing others for who they uniquely are. Safe spaces can look like honest conversations, circles of dialogue and listening and classrooms where mistakes are integral stepping stones to learning.
                                </li>
                                <li>
                                    Kids and Educators as Partners: We believe that partnership in education is where educators and students listen to and learn from each other. It shifts control and decision making from being in the hands of the adult to sharing power and responsibility with students. It enables students to play a role at all levels of the education system in determining why education matters, what education should be and how it should be delivered. Partnership can look like peer-to-peer learning or mentoring, students actively involved in school governance, students speaking at education conferences and students advising the government on education policy.
                                </li>
                                <li>
                                    Kids as Changemakers: We believe that when kids identify a problem they care about and go through a process to find a solution they grow in their learning and leadership in unprecedented ways. We have seen children of all ages and diverse backgrounds engage in community change and grow both as students and as citizens in the process.
                                </li>
                            </ul>

                            <div style={{ marginLeft: 30 }} className="title">8C’s</div>
                            <ul style={{ marginLeft: 50 }}>
                                <li>
                                    Creativity
                                </li>
                                <li>
                                    Collaboration
                                </li>
                                <li>
                                    Critical Thinking
                                </li>
                                <li>
                                    Communication
                                </li>
                                <li>
                                    Compassion
                                </li>
                                <li>
                                    Courage
                                </li>
                                <li>
                                    Curiosity
                                </li>
                                <li>
                                    Consciousness
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="card mt40">
                        <div className="title primary-text font16">
                            Garden of Hope
                        </div>

                        <div className="desc font12">
                            Garden of Hope, our very own edible gardens to bring together learning and practice to build a sustainable community. Urgent issues such as climate change, food scarcity, malnutrition, and loss of biodiversity are highly complex and contested in both science and society. This multitude of issues can be clubbed under sustainability issues. Without the involvement of the young meeting these challenges the future seems bleak. Therefore, based on the Nai Talim school model the school has started the concept of sustainable living which includes farming as a practice. Farming is practiced through our very own greenhouse which functions as our “Garden of Hope” or edible garden . These gardens offer a traditional framing experience along with using the curriculum designed by the Navdanya Foundation, run by Dr. Vandana Shiva which offers complete syllabi for cultivating food democracy. The creation of “edible gar-dens” by school, with the involvement of a wide range of societal actors (e.g., a local garden center, a restaurant, a community organization, and the local government), simultaneously improve the quality and relevance of their education and transform their relationship with the local community. Soil preparation, seed selection, planting, maintaining and harvesting, require basic scientific knowledge that connects with the Science curriculum while also creating other benefits, such as community engagement, learner empowerment, improved personal health, and a better connection with food and place. Younger students engaging and grounding themselves in physical activities such as farming also address issues of adolescence and teenage years. With the hope of all the above we have started our very own “Garden of Hope”.
                        </div>

                    </div>

                    <div className="card mt40">
                        <div className="title primary-text font16">
                            The Up-cycling Club
                        </div>

                        <div className="desc font12">
                            Dolphin International School, Pulwama, kick started The Up-Cycling Club world Environment Day, 5th June of 2018. The project has been pioneered by the students of DIS to reimagine waste-disposal in Pulwama and to set a system for mobilizing various stakeholders towards collective action. The first phase of the project included making of a 200 liters compost unit for school, sensitizing students about segregation and setting up a maker’s lab to use dry trash for innovative problem solving. Students from all grade levels starting Pre-nursery to Grade 10 are actively involved in managing waste at school. Each class has two dust-bins handmade by students to collect inorganic and organic trash separately. The organic trash from classes is moved daily to the compost unit. The compost unit is made with the aim to make manure for an on-campus green-house unit that school is setting up. The dry trash collected in school, from home and streets, has been stored in the maker’s lab and students across grades have made multiple innovative products using them. DIS Students also hosted interactive events at their homes, invited their neighbors, friends and cousins residing in the vicinity did public presentations on how to segregate waste, make compost fertilizer at home using food waste and how to creatively reduce and recycle all non-biodegradable waste produced at home. The club aims to engage 3000+ children in managing waste produced in schools and households in the valley by helping schools set up their own recycling units.
                        </div>

                    </div>

                    <div className="card mt40">
                        <div className="title primary-text font16">
                            TALK TO KNOW (T2K)
                        </div>

                        <div className="desc font12">
                            The T2k conference began in the year 2018 as a student conference program aimed at creating a platform where students talk to discover alternatives, expand perspectives, and construct solutions. T2K invites inspiring individuals from all walks of life and weaves student conversations with them. The themes of these conversations are curated for cultural integration, creative risk-taking, spirituality, global education and everything else that a growing child can learn from. The Talk to Know conference pushes limits, widens horizons, and moves students from information hoarding to knowledge making.
                        </div>
                    </div>
                </div>
                <div className="right_side">
                    <BlogLine size={5} />
                </div>



            </PageStructure>

            <AcademicPartners />


        </div>



    )

}

export default EnrichmentArts;