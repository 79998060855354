import { useRecoilValue } from "recoil";
import ContentContainer from "../../components/ContentContainer"
import { stepsState } from "../../Recoil/atoms";
import LPDForm from "./sections/lpd";
import ReactDropdown from "../../components/react-dropdown";
import { useState } from "react";
import HIGHER from "./sections/higher";

const AdmissionForm = () => {
    const stepsMenu = useRecoilValue(stepsState)

    const onStepClick = (item) => {
    }
    const depts = [
        {
            id: "lpd",
            name: "Foundation",
        },
        {
            id: "higher",
            name: "Preparatory",
        },
        {
            id: "middle",
            name: "Middle",
        },
        {
            id: "secondary",
            name: "Secondary",
        },
    ];
    const [dept, setDept] = useState(null);
    return (
        <div className="general_page">
            <ContentContainer>

                <div style={{ width: "100%", flex: 1, height: "100px", display: "grid", placeItems: "center" }}>
                    <div style={{ width: 300 }}>
                        <ReactDropdown
                            label="Select Department"
                            data={depts}
                            placeholder="Select Department"
                            value={dept}
                            onSelectChange={(val) => setDept(val)}
                        />
                    </div>

                </div>


                {dept && dept.id === "lpd" && <LPDForm dept={dept} />}
                {dept && (dept.id === "higher" || dept.id === "middle" || dept.id === "secondary") && <HIGHER dept={dept} />}

                {/* <div style={{ marginTop: 60 }}>
                    <Steps menuButtons={stepsMenu} onStepClick={onStepClick} />
                </div> */}

                {/* {stepsMenu[0].active && <PersonalDetails />}
                {stepsMenu[1].active && <AcademicDetails />}
                {stepsMenu[2].active && <Decleration />} */}


            </ContentContainer>

        </div>
    )
}


export default AdmissionForm;