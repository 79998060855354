import React, { useEffect, useState } from "react";
import Blog from "./Blog";
import { callApi } from "../api/callApi";
import AppData from "../AppData";
import { useHistory } from "react-router-dom";

const BlogLine = ({ size = 4 }) => {
  const [blogs, setBlogs] = useState([]);
  const router = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);

    let blogsLocal = [];

    callApi({
      url: AppData.BASE_URL + "api/v1/blogs",
      method: "GET",
      callback: (result) => {
        if (result?.data?.data?.length) {
          result?.data?.data?.slice(0, size).map((blog) => {
            blogsLocal.push({
              id: blog.id,
              image:
                AppData.BASE_URL + result.data.mediaPath + "/" + blog.image,
              title: blog.title,
              desc: blog.description,
              author: blog.user?.name,
              date: blog.event_date ?? "NA",
            });
          });

          setBlogs(blogsLocal);
        }
      },
    });
  }, []);

  return (
    <React.Fragment>
      <div className="bold-text font18 primary-text title">Latest Events</div>

      {blogs?.map((blog) => (
        <Blog blog={blog} />
      ))}

      <div
        className="text-center view_all bold-text"
        onClick={() => router.push("/blogs")}
      >
        View All
      </div>
    </React.Fragment>
  );
};

export default BlogLine;
