import React from "react";

const Appointment = () => {
  return (
    <div className="app_wrap">
      <div className="app_text font14">
        Please visit us with a prior appointment between 9.00 am to 2.00 pm from
        Monday to Friday for a campus tour, information about the school,
        curriculum overview, facilities and fee structure.
      </div>
      {/* <div className="app_email bold-text">admissions@dolphin.in</div>
      <div className="app_email bold-text">
        Enquire &amp; Visit <br /> Click here to fill the enquiry form
      </div> */}
    </div>
  );
};

export default Appointment;
