const SplashTitle = ({ title }) => {
  return (
    <div
      className="main-areas-heading splash-title bold-text font22 text-center"
      style={{ marginBottom: 22 }}
    >
      {title}
    </div>
  );
};

export default SplashTitle;
