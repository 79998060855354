import React from "react";
import YouTube from "react-youtube";
const Video = ({ videoUrl, thumbnailUrl, title }) => {
  const containerStyle = {
    // backgroundImage: `url(${thumbnailUrl})`,
  };
  return (
    <div className="video-container" style={containerStyle}>
      {/* <div > */}
      {/* <video className="video" controls muted preload="auto" poster={thumbnailUrl}>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <YouTube
        videoId={videoUrl?.split("v=")[1]}                  // defaults -> ''
        // id={string}                       // defaults -> ''
        // className={"video_iframe"}                // defaults -> ''
        iframeClassName={"video_iframe"}          // defaults -> ''
      // style={{ width: 640, height: 320 }}                    // defaults -> {}
      // title={string}                    // defaults -> ''
      // loading={string}                  // defaults -> undefined
      // opts={obj}                        // defaults -> {}
      // onReady={func}                    // defaults -> noop
      // onPlay={func}                     // defaults -> noop
      // onPause={func}                    // defaults -> noop
      // onEnd={func}                      // defaults -> noop
      // onError={func}                    // defaults -> noop
      // onStateChange={func}              // defaults -> noop
      // onPlaybackRateChange={func}       // defaults -> noop
      // onPlaybackQualityChange={func}    // defaults -> noop
      />
      {/* <div className="video-title bold-text">
        <FontAwesomeIcon icon="video" className="icon_space" /> {title}</div> */}
    </div>
  );
};

export default Video;
