import { useHistory } from "react-router-dom";
import React, { useState } from "react";

const DolphinCard = ({ item, index, tabs, setTabs, extraSection, hideDescription = false }) => {




    const router = useHistory();

    const [show, setShow] = useState(false);

    const gotoDetails = () => {

        let newTabs = [];

        tabs?.map((oldTab) => {
            if (oldTab.link === item.link) {
                newTabs.push({ ...oldTab, active: true });
            } else {
                newTabs.push({ ...oldTab, active: false });
            }
        });


        setTabs(newTabs)

        router.push(item.link)

    }
    return (
        <div className={extraSection ? "dolphin_card_wrapper mt30" : "dolphin_card_wrapper"}>


            <div className="section" key={index} onClick={gotoDetails}>
                <img src={item.image} alt="" />

                <div className="overlay">
                    <div className="overlay_content">
                        <div className="title font16 bold-text">
                            {item.title}
                        </div>
                        {!hideDescription && <div className="desc bold-text">{item.desc}</div>}
                    </div>
                </div>
            </div>

            {extraSection &&

                <div className="extra_section mt30">
                    <div className="more_details_heading">
                        <span className="almuni_name font14">{`${item.title}`} </span>
                        <img src={show ? "/images/icons/arrow-up.png" : "/images/icons/arrow-down.png"} alt="chevron" onClick={() => setShow(!show)} />
                    </div>

                    {show ? <div className="card mt20 font12" dangerouslySetInnerHTML={{ __html: item.about }}>
                    </div> : null}
                </div>}
        </div>
    )
}

export default DolphinCard;