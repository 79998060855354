import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";

const Default = () => {
  const [contactUsTabs, setContactUsTabs] = useState([
    { id: 0, name: "Contact Us", active: true },
    { id: 1, name: "Map Address", active: false },
  ]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getContactDetails();
  }, []);

  const getContactDetails = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/contacts",
      method: "GET",
      callback: (result) => {
        if (result && result.data && result.data.data) {
          setDetails(result.data.data[0]);
        }
      },
    });
  };

  const contactDetails = () => {
    return (
      <div className="contact-us-container">
        <div className="upper-half">
          <div className="right-half">
            <div>
              <span id="address">
                {/* Address: <span className="bold-text">{details?.address}</span>{" "} */}
                Address:{" "}
                <span className="bold-text">{"Tengpuna, Pulwama, Jammu & Kashmir, 192301"}</span>{" "}
              </span>
            </div>
            <div>
              <span id="phone-no">
                Phone: <span className="bold-text">{"+91 9906944622"}</span>{" "}
                {/* Phone: <span className="bold-text">{details?.mobile}</span>{" "} */}
              </span>
            </div>
            <div>
              <span id="email">
                Email:{" "}
                <span className="bold-text">{"dolphin.in.schl@gmail.com"}</span>
                {/* Email: <span className="bold-text">{details?.email}</span> */}
              </span>
            </div>
            <div>
              <span id="whatsapp">
                Whatsapp: <span className="bold-text">{"+91 9797722202"}</span>{" "}
                {/* Whatsapp: <span className="bold-text">{details?.whatsapp}</span>{" "} */}
              </span>
            </div>
          </div>
          <div className="left-half">
            <div>
              <span id="contact-us" className="bold-text">
                Contact Us
              </span>
            </div>
            <div>
              <span id="details" className="general-text">
                {/* {details?.details} */}
                {"info@dolphinschool.edu.in"}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="lower-half"> </div> */}
      </div>
    );
  };

  const geoLocation = () => {
    const location = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13250.670618936396!2d74.91469219845669!3d33.872454989300046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e1f45c3f9965db%3A0xcce3141236afa80b!2zRG9scGhpbiBJbnRlcm5hdGlvbmFsIFNjaG9vbCDaiNmI2YTZgdmGINio24zZhiDYp9mE2KfZgtmI2KfZhduMINiz2qnZiNmE!5e0!3m2!1sen!2sin!4v1698910296039!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade`;
    return (
      <div className="map-container">
        <div>
          <iframe
            className="google-map-image"
            src={location}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    );
  };

  return (
    <div>
      <HeroBanner name="Contact Us" image={"/images/Enrichmentheronew.jpg"} />

      <div className="rest-page content-wrapper">
        <SingularTabs tabs={contactUsTabs} setTabsState={setContactUsTabs} noNavigation={true} />
        <div>
          {contactUsTabs[0].active ? contactDetails() : null}
          {contactUsTabs[1].active ? geoLocation() : null}
        </div>
      </div>
    </div>
  );
};

export default Default;
