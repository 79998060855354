import React from "react";
import MultilevelDropdown from "./MultilevelDropdown";
import { useHistory } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

function StickyHeader(props) {
  let history = useHistory();

  return (
    <div className="header_main_wrapper">
      <div className="top_links">
        <span className="top_link" onClick={() => history.push("/careers")}>Careers</span>
      </div>
      <motion.div
        className="header-parent_sticky"
        initial={{ opacity: 0.6, y: -20 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.05 } }}
      >
        {/* <div className="header-parent_sticky"> */}
        <div className="logo-conntainer" onClick={() => history.push("/")}>
          <img src="/images/logo2.jpg" alt="logo" />
        </div>
        <div className="links-container font15">
          <MultilevelDropdown />
        </div>
        {/* </div> */}
      </motion.div>

    </div>

  );
}

export default StickyHeader;
