import { useEffect, useState } from "react";
import BlogLine from "../../../components/BlogLine";
import GeneralTitle from "../../../components/GeneralTitle";
import PageStructure from "../../../components/PageStructure";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import { callApi } from "../../../api/callApi";
import AppData from "../../../AppData";

const LearningPrepatory = () => {

    const [pageIntro, setPageIntro] = useState([])
    const [facultyData, setFacultyData] = useState([])

    useEffect(() => {
        loadFoundtionPrepatory()
    }, [])

    const loadFoundtionPrepatory = () => {
        callApi({
            url: AppData.BASE_URL + "api/v1/page_intros/learning/prepatory",
            method: "GET",
            callback: (result) => {

                if (
                    result &&
                    result.data &&
                    result.data.data &&
                    result.data.data.length > 0
                ) {
                    setPageIntro(result.data.data[0])
                }
            },
        });

        callApi({
            url: AppData.BASE_URL + "api/v1/profiles/faculty",
            method: "GET",
            callback: (result) => {
                console.log({ result })
                if (result?.data?.data) {

                    let data = {
                        profile: result.data.data.filter(it => it.position.includes("Preparatory")),
                        mediaPath: result.data.mediaPath
                    }
                    setFacultyData(data)
                }
            },
        });
    };

    return (
        <div className="general_page community_wrapper">

            <div className="title font16 bold-text secondary-text font22">
                <GeneralTitle title={"Preparatory STAGE (GRADE 3 to GRADE 5)"} />
            </div>
            <PageStructure>
                <div className="left_side page_content">
                    {/* <div className="images_only mb50">
                        <img src="/images/logo.png" alt="Recognition" />
                    </div> */}

                    <div className="recognition mb50">
                        <div className="icon">
                            <img src="/images/logo.png" alt="Recognition" />
                        </div>
                        <div className="desc font22">
                            The Preparatory Stage at Dolphin extends learning by helping students develop literacy, numeracy, and cognitive abilities.
                        </div>
                    </div>

                    <div className="card intro_slide white-text mt10" dangerouslySetInnerHTML={{ __html: pageIntro?.description }} />

                    {/* <div className="faculty_spotlight mt40 outline">
                        <img src="/images/child_protection.webp" alt="faculty" />

                        <div className="more_details pd20">

                            <div className="title primary-text font16">
                                Approaches to Teaching and Learning:
                            </div>

                            <div className=" general-text font12 text-justify desc">
                                The inquiry based approach of the IB helps students to learn skills and concepts in contexts connected to their real life. This makes learning engaging and meaningful for the students and enriching for the teachers. A unifying thread throughout all MYP subject groups, approaches to learning (ATL) provide the foundation for independent learning and encourage the application of their knowledge and skills in unfamiliar contexts. Developing and applying these social, thinking, research, communication and self management skills helps students learn how to learn.
                            </div>
                        </div>

                    </div> */}

                    <div style={{ marginTop: 20 }}>
                        <div className="heading title primary-text font16">
                            Programme Leaders
                        </div>
                        {facultyData && facultyData?.profile?.length && <div className="card_container card_container_learning">
                            {facultyData?.profile?.map(faculty => {
                                return (
                                    <div className="card">
                                        <div className="faculty_spotlight mt40 outline">
                                            <img src={`${AppData.BASE_URL}${facultyData?.mediaPath
                                                }/${faculty?.image}`} alt="" />
                                        </div>
                                        <div className="name_postion">
                                            <div className="name">
                                                {faculty?.name}
                                            </div>
                                            <div className="position">
                                                {faculty?.position?.replace("Preparatory ", "")}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>

                    {/* <div className="title mt30 text-center font18">Parent Teacher Association (PTA)</div> */}

                    {/* <div className="faculty_spotlight mt20">
                        <img src="/images/prep.JPG" alt="faculty" />

                        <div className="cardless_desc general-text font12 text-justify white-text">

                            <div className="title white-text font16">
                                The Preparatory Years Programme at Dolphin
                            </div>
                            The importance of teachers in any academic programme cannot be overemphasized, especially in the IB methodology. All of our faculty members are IB trained and most of them have significant previous experience in IB schools around the world. A diverse faculty goes a long way in preparing students to adapt to a rapidly globalizing world.

                            Dolphin International School has the most diverse community of teachers in Bangalore with teachers coming from various countries such as UK, France, USA, Australia, Canada, South Africa, Philippines, Taiwan, New Zealand, Sri Lanka, as well as India.

                            As teachers they are lifelong learners. Dolphin invests in the continuous development of teachers with local and international workshops and seminars.
                        </div>

                    </div> */}

                </div>

                <div className="right_side">
                    <BlogLine size={3} />
                </div>
            </PageStructure>
            <AcademicPartners />
        </div>
    )

}

export default LearningPrepatory;