import React, { useState } from "react";
import { TabsState, TabDataState } from "../Recoil/atoms";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

const SingularTabs = (props) => {
  const [activeTabData, setActiveTabData] = useState(props.tabs[0]);

  let history = useHistory();
  const gotoScreen = (screenName) => {
    history.push(screenName);
  };

  const onTabClick = (tab) => {
    let newTabs = [];


    if (props.noNavigation) {

      console.log("inside")
      props.tabs &&
        props.tabs.length > 0 &&
        props.tabs.map((oldTab) => {
          if (oldTab.id === tab.id) {
            newTabs.push({ ...oldTab, active: true });
          } else {
            newTabs.push({ ...oldTab, active: false });
          }
        });
      props.setTabsState(newTabs);

    } else {
      gotoScreen("/" + tab.link)

    }



  };

  return (
    <div className="tabs-and-data">
      <div className="tabs-container">
        {props.tabs &&
          props.tabs.length > 0 &&
          props.tabs.map((tab, key) => {
            return (
              <div
                className={tab.active ? "tab active-tab" : "tab secondary-text"}
                key={key}
                onClick={() => onTabClick(tab)}
              >
                <span className="font13 bold-text font13sm tab-name">{tab.name}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SingularTabs;
