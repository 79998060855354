import React, { useEffect, useState } from "react";
import HeroBanner from "../../components/HeroBanner";
window.jsPDF = window.jspdf.jsPDF;

const Default = () => {

  function convertToPdf() {
    var doc = new jsPDF({
      orientation: 'landscape'
    });

    // Source HTMLElement or a string containing HTML.
    var elementHTML = document.querySelector("#contentToPrint");

    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        doc.save('certificate.pdf');
      },
      margin: [10, 10, 10, 10],
      autoPaging: 'text',
      x: 0,
      y: 0,
      width: 190, //target width in the PDF document
      windowWidth: 675 //window width in CSS pixels
    });
  }

  return (
    <div>
      <HeroBanner name="What We Do? Our Works and Goals!" />


      <div id="contentToPrint" style={{}}>
        <h1 className="text-center">What is Lorem Ipsum?</h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img style={{ height: 60, width: "auto" }} src="/images/dolphin_fc.png" />
        </div>
        <div style={{ textAlign: "center", fontSize: 16, marginTop: 20 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
      </div>



      <button onClick={() => convertToPdf()}>Convert HTML to PDF</button>
    </div>
    // </div>
  );
};

export default Default;
