import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import ContentContainer from "../../components/ContentContainer";
import SplashTitle from "../../components/SplashTiltle";
import AcademicPartners from "../../components/page-components/AcademicPartners";
import DolphinCard from "../../components/DolphinCard";
import { useRecoilState } from "recoil";
import { aboutPageTabsState } from "../../Recoil/atoms";
import VideoLine from "../../components/VideoLine";
import Slider from "../../components/Slider";
import GeneralTitle from "../../components/GeneralTitle";

const AboutSkuastK = () => {
  const [aboutTabs, setAboutTabs] = useRecoilState(aboutPageTabsState);
  const [homeBanners, setHomeBanners] = useState(null);
  const [pageIntro, setPageIntro] = useState([])
  const [sections, setSections] = useState([
    {
      id: 0,
      image: "/images/hero/about-hero.JPG",
      title: "About Dolphin",
      desc:
        "Founded in 2008, Dolphin is proud to be an accredited and authorised IB World School. We are dedicated to our mission and vision, and our Strategic Plan for 2020 - 2024.",
      active: true,
      link: "about-us/about_dolphin"
    },
    {
      id: 1,
      image: "/images/hero/leadership.jpg",
      title: "Leadership",
      desc:
        "Dolphin is a not-for-profit school and is governed by a five-member, appointed Governing Council consisting of prominent business leaders, former parents, and the founder’s representative. The Governing Council acts under the delegated authority of the Board of Dolphin Education Foundation. ",
      active: false,
      link: "about-us/leadership"
    },
    {
      id: 2,
      image: "/images/hero/guiding_statements.jpeg",
      title: "Guiding Statements",
      desc:
        "At Dolphin, we are dedicated to our Mission and Vision Statement. As you visit our campus and website, you will see that our learning community is dedicated to the development of knowledgeable, caring, confident and responsible global citizens. ",
      active: false,
      link: "about-us/guiding_statements"
    },
    {
      id: 3,
      image: "/images/hero/community.JPG",
      title: "Community",
      desc:
        "Dolphin has one of the most diverse communities of learners in Bangalore with students from more than thirty five countries and teachers from over fifteen. ",
      active: false,
      link: "about-us/community"
    },
  ]);

  const [slides, setSlides] = useState([
    {
      id: 0,
      url: "/images/exp_items/boarding.jpg",
      caption: "Slide 1 caption",
    },
    {
      id: 1,
      url: "/images/exp_items/tech.jpg",
      caption: "Slide 2 caption",
    },
    {
      id: 2,
      url: "/images/exp_items/art.jpg",
      caption: "Slide 3 caption",
    },
    {
      id: 3,
      url: "/images/exp_items/beyond_classroom.jpg",
      caption: "Slide 4 caption",
    },
  ]);

  useEffect(() => {
    loadAboutCMS();
    loadHomeBanners();
    window.scrollTo(0, 0);
  }, []);

  const loadHomeBanners = () => {
    let homeBannersLocal = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/banners/home",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          result.data.data.map((item) => {
            homeBannersLocal.push({
              id: item.id,
              url: AppData.MEDIA_URL + "banners/" + item.b_image,
              caption: item.b_caption,
            });
          });

          setHomeBanners(homeBannersLocal);
        }
      },
    });
  };

  const loadAboutCMS = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/main_page_intros/about_us",
      method: "GET",
      callback: (result) => {

        if (

          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          setPageIntro(result.data.data[0])
        }
      },
    });
  };

  return (
    <div className="aboutskuast-k-container">
      <HeroBanner name="About DIS" image={"/images/hero/about_main_page.jpeg"} />


      <ContentContainer>
        <div className="about_container">
          {/* <SplashTitle title={"ABOUT"} /> */}
          <GeneralTitle title={"AbOUT"} />
          <div className="brief_intro card font12" dangerouslySetInnerHTML={{ __html: pageIntro?.description }}>

          </div>

          {homeBanners && <div className="mt40"> <Slider banners={slides} class={"height500"} hideCaption={true} /></div>}

          <div className="more_sections_title">
            <GeneralTitle title={"IN THIS SECTION"} />
          </div>

          <div className="other_sections">
            {sections?.map((section, index) => <DolphinCard item={section} hideDescription={true} index={index} tabs={aboutTabs} setTabs={setAboutTabs} />)}
          </div>

          <AcademicPartners />
          <VideoLine />
        </div>
      </ContentContainer>
    </div>
  );
};
export default AboutSkuastK;
