import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import ContentContainer from "../../components/ContentContainer";
import SplashTitle from "../../components/SplashTiltle";
import AcademicPartners from "../../components/page-components/AcademicPartners";
import DolphinCard from "../../components/DolphinCard";
import { useRecoilState } from "recoil";
import { learningItemsState, learningPageTabsState } from "../../Recoil/atoms";
import GeneralTitle from "../../components/GeneralTitle";

const LearningHome = () => {
  const [learningTabs, setlearningTabs] = useRecoilState(learningPageTabsState)
  const [sections, setSections] = useRecoilState(learningItemsState);
  const [pageIntro, setPageIntro] = useState(null)

  useEffect(() => {
    loadMainPageData();
    window.scrollTo(0, 0);
  }, []);

  const loadMainPageData = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/main_page_intros/learning",
      method: "GET",
      callback: (result) => {
        console.log({ result })
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {


          setPageIntro(result.data.data[0])

          // setAboutTabsData(result.data.data);
        }
      },
    });
  };

  return (
    <div className="aboutskuast-k-container">
      <HeroBanner name="About DIS" image={"/images/FoundationStage.jpg"} />

      <ContentContainer>
        <div className="about_container">
          <GeneralTitle title={"Learning"} />
          <div className="brief_intro card font12" dangerouslySetInnerHTML={{ __html: pageIntro?.description }} />

          <div className="more_sections_title">
            <GeneralTitle title={"In this section"} />
          </div>

          <div className="other_sections">
            {sections?.map((section, index) => <DolphinCard item={section} index={index} tabs={learningTabs} setTabs={setlearningTabs} />
            )}
          </div>

          <AcademicPartners />
        </div>
      </ContentContainer>
    </div>
  );
};
export default LearningHome;
