import React from "react";
import HeroBanner from "../../components/HeroBanner";
import ContentContainer from "../../components/ContentContainer";
import AcademicPartners from "../../components/page-components/AcademicPartners";
import { useRecoilValue } from "recoil";
import { currentBlogState } from "../../Recoil/atoms";
import PageStructure from "../../components/PageStructure";
import BlogLine from "../../components/BlogLine";
import VideoLine from "../../components/VideoLine";

const BlogPage = () => {
    const currentBlog = useRecoilValue(currentBlogState);

    return (
        <div className="blogs_wrapper">
            <HeroBanner name="About DIS" />

            <ContentContainer>
                <div className="title font16 bold-text secondary-text font22">{currentBlog.title}</div>
                <PageStructure>
                    <div className="left_side news_wrapper">
                        {/* <div className="blog_author">
                            <div>
                                By {currentBlog.author}
                            </div>
                            <div>{currentBlog.profession}</div>
                        </div> */}

                        <div className="image_desc_cont">
                            <div className="auth_img">
                                <img src={currentBlog.image} alt="" />
                            </div>
                        </div>
                        <div className="title mt10">{currentBlog.title}</div>
                        <div className="date font14 mt0">{new Date(currentBlog.date).toLocaleDateString()}</div>
                        <div className="auth_desc text-justify mt10 font14" dangerouslySetInnerHTML={{ __html: currentBlog.desc }}></div>
                        {/* <div className="video_container">
                            <div className="heading">Here is a small video on the workshop</div>

                            <div className="video">
                                <img src={currentBlog.image} alt="" />
                            </div>
                        </div> */}
                    </div>
                    <div className="right_side">
                        <BlogLine />
                    </div>


                </PageStructure>
                <VideoLine />
                <AcademicPartners />
            </ContentContainer>
        </div>
    );
};


export default BlogPage;
