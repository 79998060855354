import { useRecoilState } from "recoil";
import MyInput from "../../../components/my-input";
import { admissionInputState, stepsState } from "../../../Recoil/atoms";
import ReactDropdown from "../../../components/react-dropdown";
import MyButton from "../../../components/my-button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { range } from "lodash";
import MyCheckbox from "../../../components/checkbox/kupos-checkbox";
import { callApi } from "../../../api/callApi";
import AppData from "../../../AppData";
import MyModal from "../../../components/MyModal";
import { useHistory } from "react-router-dom";

const HIGHER = ({ dept }) => {
    let history = useHistory();

    const getYear = (date) => {
        return date.getFullYear()
    }

    const getMonth = (date) => {
        return date.getMonth()
    }

    const [modalData, setModalData] = useState({ show: false, title: "", description: "" })
    const [startDate, setStartDate] = useState(new Date());
    const years = range(1990, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const genders = [
        {
            id: "male",
            name: "Male",
        },
        {
            id: "female",
            name: "Female",
        },
        {
            id: "other",
            name: "Other",
        },
    ];

    const [checked, setChecked] = useState(false)
    const [medical, setMedical] = useState(false)
    const [nonMedical, setNonMedical] = useState(false)
    const [checkBoxError, setCheckBoxError] = useState(null)
    const [subjectError, setSubjectError] = useState(null)


    const [inputs, setInputs] = useRecoilState(admissionInputState)


    const onChange = (val, type) => {
        let error = `${type}Error`;

        if (type === "dob") {
            setInputs({ ...inputs, [type]: new Date(val).toLocaleDateString(), [error]: null });
        } else {
            setInputs({ ...inputs, [type]: val, [error]: null });
        }
    };

    const validateInputs = () => {
        let errors = {};
        let requiredFields = ['name', 'gender', 'district', 'fathers_name', 'mothers_name', 'address_details', 'pin_code', 'phone_number', 'phone_number_2',
            'class_to_join', 'previous_school', 'additional_subject', 'previous_exam', 'previous_year', 'roll_no', 'marks', 'percentage', 'board'];

        requiredFields.forEach(field => {
            if (!inputs[field]) {
                errors[`${field}Error`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        if (inputs?.mobile?.length < 10) {
            errors["mobileError"] = "Mobile must be 10 digits!";
        }

        setInputs({ ...inputs, ...errors });

        if (!checked) {
            setCheckBoxError("Please agree to the terms and conditions")
            return;
        }

        if (!medical && !nonMedical) {
            setSubjectError("Please chooe your subject")
            return;
        }

        // If there are any errors, return false. Otherwise, return true.
        return Object.keys(errors).length === 0;
    }


    console.log({ inputs })

    const continueTo = () => {
        if (!validateInputs()) {
            return;
        }


        let data = {
            dept: dept?.name,
            name: inputs.name,
            dob: inputs.dob,
            gender: inputs.gender?.label,
            fathers_name: inputs.fathers_name,
            fathers_occupation: inputs.fathers_occupation,
            mothers_name: inputs.mothers_name,
            mothers_occupation: inputs.mothers_occupation,
            address: inputs.address_details,
            district: inputs.district,
            pincode: inputs.pin_code,
            phone: inputs.phone_number,
            alternate_phone: inputs.phone_number_2,
            admission_for: inputs.class_to_join,
            previous_school: inputs.previous_school,
            subject: medical ? "Medical" : nonMedical ? "Non-Medical" : "NA",
            additional_subject: inputs.additional_subject,
            previous_exam: inputs.previous_exam,
            year_of_passing: inputs.previous_year,
            roll_no: inputs.roll_no,
            marks: inputs.marks,
            percentage: inputs.percentage,
            board_name: inputs.board,
        }

        console.log("all good", data);


        // return;



        callApi({
            url: AppData.BASE_URL + "api/v1/admission",
            method: "POST",
            callback: (res) => {

                console.log("res from admissions form----", res);
                if (res?.data && res?.data?.success) {

                    setModalData({ show: true, title: "Success", description: "Your form has been submitted successfully" })

                    setTimeout(() => {
                        history.push("/")
                    }, 3000);

                } else {
                    setModalData({ show: true, title: "Error", description: "Something went wrong" })
                }
            },
            data: data
        });

    }

    return (
        <div className="form_wrapper" style={{ paddingTop: 10 }}>

            <div className="secondary-text bold-text">Department selected: {dept?.name}</div>

            <div className="inputs_row">

                <MyInput
                    label="Name"
                    placeholder="Name"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "name")}
                    value={inputs.name}
                    error={inputs.nameError ? inputs.nameError : null}
                />

                <div className="mt20" style={{ width: "40%" }}>
                    <div className={"label"}>{"DOB"} </div>
                    <DatePicker
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                >
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                        selected={inputs.dob ? new Date(inputs.dob) : new Date()}
                        onChange={(date) => onChange(date, "dob")}
                    // style={{ height: 40, width: "100%", border: "1px solid #ccc", borderRadius: 5, padding: 10 }}
                    />
                    {inputs?.dobError && <div className={"error"}>{inputs?.dobError} </div>}
                </div>

                <div style={{ width: "40%" }}>
                    <ReactDropdown
                        label="Gender"
                        data={genders}
                        placeholder="Gender"
                        value={inputs.gender}
                        onSelectChange={(val) => {
                            onChange(val, "gender");
                        }}
                        style={{ height: 40, width: "100%", border: "1px solid #ccc", borderRadius: 5, padding: 10 }}
                        error={inputs.genderError ? inputs.genderError : null}
                    />

                </div>

            </div>

            <div className="inputs_row">
                <MyInput
                    label="Father/Gaurdian Name"
                    placeholder="First Name"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "fathers_name")}
                    value={inputs.fathers_name}
                    error={inputs.fathers_nameError ? inputs.fathers_nameError : null}
                // leftIcon={"/images/icons/home/search.png"}
                />

                <MyInput
                    label="Mothers Name"
                    placeholder="Mothers Name"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "mothers_name")}
                    value={inputs.mothers_name}
                    error={inputs.mothers_nameError ? inputs.mothers_nameError : null}
                // leftIcon={"/images/icons/home/search.png"}
                />



            </div>
            {/* 
            <div className="inputs_row">
                <MyInput
                    label="Mothers Name"
                    placeholder="Mothers Name"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "mothers_name")}
                    value={inputs.mothers_name}
                    error={inputs.mothers_nameError ? inputs.mothers_nameError : null}
                // leftIcon={"/images/icons/home/search.png"}
                />

                <MyInput
                    label="Mothers Occupation and Monthly Income"
                    placeholder="Mothers Occupation and Monthly Income"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "mothers_occupation")}
                    value={inputs.mothers_occupation}
                    error={inputs.mothers_occupationError ? inputs.mothers_occupationError : null}
                // leftIcon={"/images/icons/home/search.png"}
                />

            </div> */}



            <div className="inputs_row">
                <MyInput
                    label="Address details"
                    placeholder="Address details"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "address_details")}
                    value={inputs.address_details}
                    error={inputs.address_detailsError ? inputs.address_detailsError : null}
                // leftIcon={"/images/icons/home/search.png"}
                />

                <MyInput
                    label="District"
                    placeholder="District"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "district")}
                    value={inputs.district}
                    error={inputs.districtError ? inputs.districtError : null}
                />

                <MyInput
                    label="Pin Code"
                    placeholder="Pin Code"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "pin_code")}
                    value={inputs.pin_code}
                    error={inputs.pin_codeError ? inputs.pin_codeError : null}
                // leftIcon={"/images/icons/home/search.png"}
                />

            </div>

            <div className="inputs_row">
                <MyInput
                    label="Phone Number"
                    placeholder="Phone Number"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "phone_number")}
                    value={inputs.phone_number}
                    error={inputs.phone_numberError ? inputs.phone_numberError : null}
                />

                <MyInput
                    label="Alternate Phone Number"
                    placeholder="Alternate Phone Number"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "phone_number_2")}
                    value={inputs.phone_number_2}
                    error={inputs.phone_number_2Error ? inputs.phone_number_2Error : null}
                />

            </div>

            <div className="inputs_row">


                <MyInput
                    label="Admission sought for the class"
                    placeholder="Admission sought for the class"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "class_to_join")}
                    value={inputs.class_to_join}
                    error={inputs.class_to_joinError ? inputs.class_to_joinError : null}

                />

                <MyInput
                    label="Previous School"
                    placeholder="Previous School"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "previous_school")}
                    value={inputs.previous_school}
                    error={inputs.previous_schoolError ? inputs.previous_schoolError : null}
                />
            </div>


            <div className="inputs_row">
                <MyCheckbox label="Medical" checked={medical} onClick={() => {
                    setMedical(!medical);
                    setNonMedical(false);
                    setSubjectError(null);
                }} />
                <MyCheckbox label="Non-Medical" checked={nonMedical} onClick={() => {
                    setNonMedical(!nonMedical);
                    setMedical(false);
                    setSubjectError(null);
                }} />
                <MyInput
                    label="Additional Subject"
                    placeholder="Additional Subject"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "additional_subject")}
                    value={inputs.additional_subject}
                    error={inputs.additional_subjectError ? inputs.additional_subjectError : null}
                />
            </div>

            {subjectError && <div className="error">{subjectError}</div>}

            <div className="inputs_row">
                <MyInput
                    label="Previous Exam Passed"
                    placeholder="Previous Exam Passed"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "previous_exam")}
                    value={inputs.previous_exam}
                    error={inputs.previous_examError ? inputs.previous_examError : null}
                />
                <MyInput
                    label="Year of Passing"
                    placeholder="Year of Passing"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "previous_year")}
                    value={inputs.previous_year}
                    error={inputs.previous_yearError ? inputs.previous_yearError : null}
                />
                <MyInput
                    label="Roll No."
                    placeholder="Roll No."
                    type="text"
                    onChange={(val) => onChange(val.target.value, "roll_no")}
                    value={inputs.roll_no}
                    error={inputs.roll_noError ? inputs.roll_noError : null}
                />
                <MyInput
                    label="Marks Obtained"
                    placeholder="Marks Obtained"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "marks")}
                    value={inputs.marks}
                    error={inputs.marksError ? inputs.marksError : null}
                />
                <MyInput
                    label="Percentage"
                    placeholder="Percentage"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "percentage")}
                    value={inputs.percentage}
                    error={inputs.percentageError ? inputs.percentageError : null}
                />
                <MyInput
                    label="Name of Board"
                    placeholder="Name of Board"
                    type="text"
                    onChange={(val) => onChange(val.target.value, "board")}
                    value={inputs.board}
                    error={inputs.boardError ? inputs.boardError : null}
                />
            </div>




            <div style={{ marginTop: 20 }}>
                <MyCheckbox label="I declare that the information provided by me is true and correct and I will abide by all the rules and regulations of the school." checked={checked} onClick={() => {
                    setChecked(!checked);
                    setCheckBoxError(null);
                }} />
            </div>
            {<div className="error">{checkBoxError}</div>}

            <MyButton
                style={{ borderRadius: 100, marginTop: "10px" }}
                onClick={continueTo}
                label="Submit"
            />


            <MyModal
                open={modalData.show}
                title={modalData.title}
                desc={modalData.description}
                onClose={() => setModalData({ show: false })}
            />
        </div>
    )
}


export default HIGHER;