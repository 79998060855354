const PageStructure = ({children}) => {


    return(
        <div className="internal_page_wrapper">
            {children}
        </div>
    )
}


export default PageStructure;