import React, { Fragment, useState } from "react";
import ContentContainer from "../../../components/ContentContainer";
import { useHistory } from "react-router-dom";
import BlogLine from "../../../components/BlogLine";
import Appointment from "../../../components/Appointment";
import LeadCard from "../../../components/LeadCard";
import SplashTitle from "../../../components/SplashTiltle";
import PageStructure from "../../../components/PageStructure";
import VideoLine from "../../../components/VideoLine";
import Prospect from "../../../components/admissions/Prospect";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import GeneralTitle from "../../../components/GeneralTitle";

const EnquireVisit = () => {
  const history = useHistory();
  const goto = (screen) => {
    history.push(screen);
  };

  const [cards, setCards] = useState([
    {
      id: 0,
      desc: " We are delighted to welcome you to the vibrant community of Dolphin International School. Central to our institution;s ethos is the belief that we don’t teach, we create conditions to learn."
    },
    {
      id: 1,
      desc: "At Dolphin International School, we redefine education by placing a paramount focus on the holistic development of each child. Beyond the confines of conventional teaching methods, we embark on a journey that explores boundaries and encourages students to reach their full potential."
    },
    {
      id: 2,
      desc: "Choosing Dolphin International School means opening doors to a world of possibilities for your child. Join us at Dolphin International School, where education extends beyond textbooks, nurturing not just academic excellence but also a strong moral compass. Our values are not just taught; they are lived, creating a foundation for a future generation of responsible and compassionate global citizens."
    },

  ]);
  return (
    <div className="general_page">
      <ContentContainer>
        {/* <SplashTitle title="Enquire" /> */}
        <GeneralTitle title="Enquire" />

        <PageStructure>
          <div className="left_side">
            <div className="bold-text font16 primary-text mb20">Share in the Dolphin Experience</div>
            <div className="card_container">
              {cards?.map(card => {
                return <div className="card secondary-card text-center font14" key={card.id}>{card.desc}</div>
              })}
            </div>

            <Prospect path="#" />

            {/* <Fragment>
              <div className="fill_form_link secondary-background pd20 mt20 white-text">
                <span className="bold-text font16 mb10 mt20">Enquire and Visit</span>
                <span className="bold-text font12 mb20">Click here to fill the enquiry form</span>
              </div>
            </Fragment> */}
            {/* <div className="element_title">
              Get In Touch With Our Admissions Team
            </div>
            <div className="other_sections">
              {sections?.map((section, index) => (
                <DolphinCard item={section} index={index} />
              ))}
            </div> */}
            <Appointment />
          </div>
          <div className="right_side">
            <BlogLine size={2} />
            {/* <LeadCard /> */}
          </div>

        </PageStructure>

        <VideoLine />
      </ContentContainer>

      <AcademicPartners />
    </div>
  );
};

export default EnquireVisit;
