import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Dropdown from "rsuite/Dropdown";
import "rsuite/dist/rsuite.min.css";
import { set } from "lodash";

const MultilevelDropdown = (props) => {
  let history = useHistory();
  const gotoScreen = (screenName) => {
    history.push(screenName);
  };

  const menu = [
    {
      title: "ABOUT",
      screen: "/about-us",
      subMenu: [
        {
          name: "About Dolphin",
          screen: "/about-us/about_dolphin",
        },
        {
          name: "Leadership",
          screen: "/about-us/leadership",
        },
        {
          name: "Guiding Statements",
          screen: "/about-us/guiding_statements",
        },
        {
          name: "Community",
          screen: "/about-us/community",
        },
        {
          name: "Mandatory Public Disclosure",
          screen: "/about-us/disclosure",
        },
      ],
    },

    {
      title: "ADMISSIONS",
      screen: "/admissions",
      subMenu: [
        {
          name: "Enquire",
          screen: "/admissions/enquire",
        },
        {
          name: "Registration",
          screen: "/admissions/apply",
        },
      ],
    },
    {
      title: "LEARNING",
      screen: "/learning",
      subMenu: [
        {
          name: "Foundation Stage",
          screen: "/learning/foundation",
        },
        {
          name: "Preparatory Stage",
          screen: "/learning/preparatory",
        },
        {
          name: "Middle Stage",
          screen: "/learning/middle",
        },
        {
          name: "Secondary Stage",
          screen: "/learning/secondary",
        },
      ],
    },

    {
      title: "THEMATIC VISION",
      screen: "/enrichment",
      subMenu: [
        {
          name: "Being the change by design",
          screen: "/enrichment/arts",
        },
        {
          name: "Innovation in Practice",
          screen: "/enrichment/technology",
        },
        {
          name: "Exposure in Exchange",
          screen: "/enrichment/sports",
        },
        {
          name: "Well being in Action",
          screen: "/enrichment/after_school_activities",
        },
        {
          name: "Celebrations",
          screen: "/enrichment/celebrations",
        },
        // {
        //   name: "Primary School Trips",
        //   screen: "/enrichment/primary_school_trips",
        // },
        // {
        //   name: "Secondary School Trips",
        //   screen: "/enrichment/secondary_school_trips",
        // },
      ],
    },
    {
      title: "EXPLORE DOLPHIN",
      screen: "/explore-dolphin",
      subMenu: [
        {
          name: "Dolphin In Media",
          screen: "/explore-dolphin/media",
        },
        {
          name: "Latest Events",
          screen: "/explore-dolphin/blogs",
        },
        {
          name: "Publications",
          screen: "/explore-dolphin/publications",
        },
      ],
    },
    // {
    //   title: "ALUMNI",
    //   screen: "/alumni",
    // },
    // {
    //   title: "Contact us",
    //   screen: "/contact-us",
    // },
  ];

  return (
    <div className="multi-level-dropdown-container">
      {menu.map((mainMenuItem, key) => {
        return mainMenuItem.subMenu ? (
          <Dropdown
            title={mainMenuItem.title}
            trigger="hover"
            placement="bottomStart"
            style={
              key == 4 ? { marginRight: 6, border: "none" } : { marginRight: 6 }
            }
            key={key}
            onClick={() => gotoScreen(mainMenuItem.screen)}
            className={key == 4 ? "last_item" : ""}
          >
            {mainMenuItem &&
              mainMenuItem.subMenu &&
              mainMenuItem.subMenu.map((subMenuItem) => {
                return subMenuItem && subMenuItem.subMenu ? (
                  <Dropdown
                    title={subMenuItem.name}
                    trigger="hover"
                    placement="rightStart"
                    style={{ marginRight: 10 }}
                  >
                    {subMenuItem.subMenu.map((innerSubItem) => {
                      return (
                        <div>
                          <Dropdown.Item
                            key={innerSubItem.id}
                            onClick={() => gotoScreen(innerSubItem.screen)}
                            className="font11"
                          >
                            {innerSubItem.name}
                          </Dropdown.Item>
                        </div>
                      );
                    })}
                  </Dropdown>
                ) : (
                  <Dropdown.Item
                    key={subMenuItem.id}
                    onClick={() => gotoScreen(subMenuItem.screen)}
                    className="font11"
                  >
                    {subMenuItem.name}
                  </Dropdown.Item>
                );
              })}
          </Dropdown>
        ) : (
          <div
            className="rs-dropdown"
            onClick={() =>
              mainMenuItem.screen ? gotoScreen(mainMenuItem.screen) : null
            }
            key={key}
          >
            <button className="rs-btn">{mainMenuItem.title}</button>{" "}
          </div>
        );
      })}
    </div>
  );
};

export default MultilevelDropdown;
