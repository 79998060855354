import HeroBanner from "../../components/HeroBanner";
import ContentContainer from "../../components/ContentContainer";
import AcademicPartners from "../../components/page-components/AcademicPartners";
import PageStructure from "../../components/PageStructure";
import BlogLine from "../../components/BlogLine";
import DolphinCard from "../../components/DolphinCard";
import { useEffect, useState } from "react";

const BlogPage = () => {
    const [sections, setSections] = useState([
        {
            id: 0,
            image: "/images/exp_items/diploma.jpg",
            title: "John Doe",
            desc: "Software Engineer",
            active: true,
            link: "about_dolphin",
            about: "DOLPHIN was the perfect stepping stone to life at university. It gave me the opportunity to meet some incredible people from all over the world and create unforgettable memories. I felt like I was part of a community and DOLPHIN will always have a special place in my heart. I'm currently studying Media and Communication at Erasmus University Rotterdam. I was a part of the Honours programme in my second year and I met some really interesting people, heard their stories, and worked on real life projects. For example, I created a media campaign for the Rotterdam Philharmonic Orchestra using storytelling. At the end of my second year, I got the opportunity to intern with the account/ strategy team at TBWA\NEBOKO, an advertising agency in Amsterdam and at the start of my third year, I attended the University of Leeds in the UK for four months  on an exchange programme, which I really enjoyed!"
        },
        {
            id: 1,
            image: "/images/exp_items/boarding.jpg",
            title: "Tesla Beem",
            desc: "Teacher Dolphin School",
            active: false,
            link: "leadership",
            about: "DOLPHIN was the perfect stepping stone to life at university. It gave me the opportunity to meet some incredible people from all over the world and create unforgettable memories. I felt like I was part of a community and DOLPHIN will always have a special place in my heart. I'm currently studying Media and Communication at Erasmus University Rotterdam. I was a part of the Honours programme in my second year and I met some really interesting people, heard their stories, and worked on real life projects. For example, I created a media campaign for the Rotterdam Philharmonic Orchestra using storytelling. At the end of my second year, I got the opportunity to intern with the account/ strategy team at TBWA\NEBOKO, an advertising agency in Amsterdam and at the start of my third year, I attended the University of Leeds in the UK for four months  on an exchange programme, which I really enjoyed!"
        },
        {
            id: 2,
            image: "/images/exp_items/diploma.jpg",
            title: "Mary Naam",
            desc: "Sr. Faculty Member KU",
            active: true,
            link: "about_dolphin",
            about: "DOLPHIN was the perfect stepping stone to life at university. It gave me the opportunity to meet some incredible people from all over the world and create unforgettable memories. I felt like I was part of a community and DOLPHIN will always have a special place in my heart. I'm currently studying Media and Communication at Erasmus University Rotterdam. I was a part of the Honours programme in my second year and I met some really interesting people, heard their stories, and worked on real life projects. For example, I created a media campaign for the Rotterdam Philharmonic Orchestra using storytelling. At the end of my second year, I got the opportunity to intern with the account/ strategy team at TBWA\NEBOKO, an advertising agency in Amsterdam and at the start of my third year, I attended the University of Leeds in the UK for four months  on an exchange programme, which I really enjoyed!"
        },
        {
            id: 3,
            image: "/images/exp_items/boarding.jpg",
            title: "Maria Beem",
            desc: "Chief Medical Officer",
            active: false,
            link: "leadership",
            about: "DOLPHIN was the perfect stepping stone to life at university. It gave me the opportunity to meet some incredible people from all over the world and create unforgettable memories. I felt like I was part of a community and DOLPHIN will always have a special place in my heart. I'm currently studying Media and Communication at Erasmus University Rotterdam. I was a part of the Honours programme in my second year and I met some really interesting people, heard their stories, and worked on real life projects. For example, I created a media campaign for the Rotterdam Philharmonic Orchestra using storytelling. At the end of my second year, I got the opportunity to intern with the account/ strategy team at TBWA\NEBOKO, an advertising agency in Amsterdam and at the start of my third year, I attended the University of Leeds in the UK for four months  on an exchange programme, which I really enjoyed!"
        },
    ]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="blogs_wrapper">
            <HeroBanner name="Alumni" image={"/images/AlumniMeet.jpg"} />
            <ContentContainer>
                <div className="title font16 bold-text secondary-text font22">Alumni</div>
                <PageStructure>
                    <div className="left_side">
                        <div className="element_title">
                            Our Wonderfull stars!
                        </div>
                        <div className="other_sections alumni_section">
                            {sections?.map((section, index) => {
                                return <DolphinCard item={section} index={index} extraSection={true} />
                            }
                            )}
                        </div>
                    </div>

                    <div className="right_side">
                        <BlogLine />
                    </div>
                </PageStructure>
                <AcademicPartners />
            </ContentContainer>
        </div>
    );
};


export default BlogPage;
