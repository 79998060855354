import React, { useEffect, useState } from "react";
import GeneralTitle from "../../../components/GeneralTitle";
import PageStructure from "../../../components/PageStructure";
import { useRecoilValue } from "recoil";
import { objectivesState } from "../../../Recoil/atoms";
import Objectives from "../../../components/Objectives";
import Blog from "../../../components/Blog";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import BlogLine from "../../../components/BlogLine";
import AppData from "../../../AppData";
import { callApi } from "../../../api/callApi";

const LearningFoundation = () => {

    const objectives = useRecoilValue(objectivesState)
    const [pageIntro, setPageIntro] = useState([])
    const [facultyData, setFacultyData] = useState([])

    useEffect(() => {
        loadLearnigFoundation()
    }, [])

    const loadLearnigFoundation = () => {
        callApi({
            url: AppData.BASE_URL + "api/v1/page_intros/learning/foundation",
            method: "GET",
            callback: (result) => {

                if (
                    result &&
                    result.data &&
                    result.data.data &&
                    result.data.data.length > 0
                ) {
                    setPageIntro(result.data.data[0])
                }
            },
        });

        callApi({
            url: AppData.BASE_URL + "api/v1/profiles/faculty",
            method: "GET",
            callback: (result) => {
                console.log({ result })
                if (result?.data?.data) {

                    let data = {
                        profile: result.data.data.filter(it => it.position.includes("Foundation")),
                        mediaPath: result.data.mediaPath
                    }
                    setFacultyData(data)
                }
            },
        });
    };

    console.log({ facultyData });

    return (
        <div className="general_page">

            <div className="title font16 bold-text secondary-text font22">
                <GeneralTitle title={"FOUNDATION STAGE (PRE-NURSERY to GRADE 2)"} />
            </div>
            <PageStructure>
                <div className="left_side page_content">

                    <div className="recognition mb50">
                        <div className="icon">
                            <img src="/images/rec_primary.png" alt="Recognition" />
                        </div>
                        <div className="desc font20">
                            At Dolphin, the Foundation Stage nurtures and develops young students as caring and active participants in a lifelong journey of learning.
                        </div>
                    </div>


                    <React.Fragment >

                        <div className="card general-text mb50" dangerouslySetInnerHTML={{ __html: pageIntro?.description }} />


                    </React.Fragment>

                    {/* <div className="faculty_spotlight mt40 outline">
                        <img src="/images/child_protection.webp" alt="faculty" />

                        <div className="more_details pd20">


                            <div className="title primary-text font16">
                                Child Protection
                            </div>

                            <div className="desc">
                                Child protection is the responsibility of all members of our team. A designated child protection officer takes on the systems-wide management. Notification of concerns is mandatory and acted upon in a timely manner.
                            </div>

                            <div className="title primary-text font16">
                                Primary School Counsellor

                            </div>

                            <div className=" general-text font12 text-justify desc">
                                A transdisciplinary programme ensures much of our work is done within the context of units of inquiry and led by the grade team along with the Primary School counsellor. Several stand-alone units target specific areas, notably sexuality education which supports the child protection elements of life and learning. Many of these stand alone units are co-taught giving learners an opportunity to relate to a number of adults who they see as a support network. This support network plays an important role as they grapple with a growing maturity and added responsibilities.
                                <br />
                                The school counsellor has additional roles including transitioning learners in and out of the school, counselling individuals and groups through ‘big problems’ and building specific skills that ensure the supportive nature of the community is enhanced and maintained.
                            </div>
                        </div>

                    </div> */}

                    <div style={{ marginTop: 20 }}>
                        <div className="heading title primary-text font16">
                            Programme Leaders
                        </div>
                        {facultyData && facultyData?.profile?.length && <div className="card_container_learning">
                            {facultyData?.profile?.map(faculty => {
                                return (
                                    <div className="card">
                                        <div className="faculty_spotlight outline">
                                            <img src={`${AppData.BASE_URL}${facultyData?.mediaPath
                                                }/${faculty?.image}`} alt="" />
                                        </div>
                                        <div className="name_postion">
                                            <div className="name">
                                                {faculty?.name}
                                            </div>
                                            <div className="position">
                                                {faculty?.position?.replace("Foundation ", "")}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>

                    {/* <React.Fragment >

                        <div style={{ marginTop: 20 }}>
                            <div className="title primary-text font16">
                                The freedom to question is the freedom to learn.
                            </div>

                            <div className="desc font11">
                                Passion and action are expectations of learning - the connections to self, family, community and the real world are indicators of significant, relevant, engaging and challenging opportunities that intrinsically motivate.

                                Embedded outcomes in the key learning areas ensure growth and development in skills, knowledge and understanding against international norms. Collaborative teams of professional staff collect, monitor and manage data/learning at the individual, grade and systems-wide levels.

                                Parents are seen as partners so feedback is regular and timely. This feedback is individualised and includes the learners self-evaluation with specific goal setting as its outcome.

                                Intercultural awareness and global citizenship are highlighted in a Primary-based year-long unit of inquiry along with a significant focus on our host culture and connections to Bangalore and India throughout the programme of inquiry.
                            </div>
                        </div>
                    </React.Fragment> */}

                    {/* <div className="card mt40">
                        <div className="title primary-text font16">
                            PERSONAL AND SOCIAL EDUCATION IN THE PYP
                        </div>

                        <div className="desc font12">
                            All members of the PYP team work together to create an environment that is supportive of learning in all realms. Being responsible, respectful, caring and honest ensures we build harmony and commitment to each other. We create solid relationships based on trust and a willingness to support each other as we all grow and learn.

                        </div>

                    </div> */}
                </div>
                <div className="right_side">
                    <BlogLine size={3} />
                </div>



            </PageStructure>

            <AcademicPartners />


        </div>



    )

}


const objectives = [
    { id: 0, title: "Learning and Teaching", desc: "Maximise and enhance every student’s potential by emphasising a personalised learning environment throughout the school based on the latest pedagogical research." },
    {
        id: 1, title: "Infrastructure, School Growth and Development", desc: "Develop flexible and responsive systems and a culture of adaptability and innovation to establish Dolphin as a school of choice in India."
    },
    { id: 2, title: "Well-Being and Child Protection", desc: "Create the best physical and emotional environment to ensure the safety and well-being of all members of the community" },
    { id: 3, title: "Governance", desc: "Ensure effective leadership which is modeled on the duty of care, learning, trust, and accountability, that best serves the school now and in the future." },
    { id: 4, title: "Financial Sustainability", desc: "Responsible planning and management of resources to be financially stable and self-sustaining." },
    { id: 5, title: "Identity", desc: "Build our reputation and be recognised as a world- class international school in India with a clear and compelling identity." },
]

export default LearningFoundation;


