import React, { useEffect, useState } from "react";
import { Slide, Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Typewriter from "typewriter-effect";
import AppData from "../AppData";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md'

function Slider(props) {
  const [slideImages, setSlideImages] = useState([]);

  useEffect(() => {
    setSlideImages(props.banners);
  }, []);

  const indicators = (index) => (<div className="indicator">{index + 1}</div>);

  const properties = {
    prevArrow: <span className="sliderBtns"><MdOutlineNavigateBefore /></span>,
    nextArrow: <span className="sliderBtns"><MdOutlineNavigateNext /></span>
  }

  return (
    <div style={{ width: "100%", overflow: "hidden", }}>
      {slideImages && slideImages.length > 0 && (
        <Fade indicators={true} {...properties}>
          {slideImages.sort((a, b) => a.id - b.id).map((slideImage, index) => (
            <div className="each-slide" key={index}>
              <div
                className={
                  props.class
                    ? `image-container ${props.class}`
                    : "image-container"
                }
                style={{ backgroundImage: `url(${slideImage.url})` }}
              >

                {props.hideCaption ? null :
                  <div className="caption-container bold-text font12">
                    <Typewriter
                      options={{
                        strings: [
                          slideImage.caption
                            ?.replace(/<p[^>]*>/g, "")
                            .replace(/<\/p>/g, ""),
                        ],
                        autoStart: true,
                        loop: true,
                        delay: 30,
                        deleteSpeed: 1,
                        pauseFor: 2000,
                      }}
                    />
                  </div>}
              </div>
            </div>
          ))}
        </Fade>
      )}
    </div>
  );
}

export default Slider;
