import React from "react";
import PageStructure from "../../../components/PageStructure";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import BlogLine from "../../../components/BlogLine";
import GeneralTitle from "../../../components/GeneralTitle";

const EnrichmentTechnology = () => {


    return (
        <div className="general_page">

            <div className="title bold-text secondary-text font22 mt30">

                <GeneralTitle title={"Innovation in Practice"} />

            </div>

            <PageStructure>
                <div className="left_side page_content">

                    <div className="main_heading_intro mb50 font16 desc primary-text">
                        The objective of this theme is to practice our capacity of scientific inquiry and responsive innovation. Students learn the skills of innovation through a practice based project which involves learning new skills and making collectively envision, and what is also the latest need of the time.
                    </div>

                    <div className="card mt40">
                        <div className="title primary-text font16">
                            ROBOTICS
                        </div>

                        <div className="desc font12">
                            At Dolphin International School, a cutting-edge initiative has taken shape with the introduction of robotics classes for not just the students of Dolphin but open to all schools of Pulwama. Those classes are not only pushing the boundaries of technological education but also fostering a sense of community engagement. This innovative program is designed to equip students with essential skills in robotics and automation, empowering them to navigate the evolving landscape of technology. The school has seamlessly integrated community-based elements into these classes, creating an environment where students collaborate on real-world projects that address local challenges. This unique approach not only enhances their technical proficiency but also instills a sense of responsibility and civic duty. The robotics classes at Dolphin International School are not just about gears and circuits; they are about cultivating a new generation of problem solvers and community leaders. Through this forward-thinking initiative, the school is not only preparing students for the future but also actively contributing to the betterment of the community at large.
                        </div>

                    </div>

                    <div className="card mt40">
                        <div className="title primary-text font16">
                            E-learning by Empathy
                        </div>

                        <div className="desc font12">
                            Opportunities to view, understand, connect with and learn from what lies in the world beyond our physical reach can be instrumental in steering not only academic but also social and emotional learning, resulting in motivated children taking charge of their lives, finding their own truth, learning to accept differences and making the world their source of knowledge. To enable the vision DIS runs a Web-Learning Program which allows teachers to bring global content into classrooms and let education go beyond books. In 2019, a friendship schooling project was rolled out as a pilot for 800 students in Pulwama, two months after the rearrangement of Kashmir’s statehood and the consequent impositions.
                        </div>

                    </div>

                    {/* <div className="desc font13 ">
                        At Dolphin, increased access to devices, tools and resources has enabled learners to become co-presenters and co-constructors of their learning. Access to an iPad or Laptop for each member of the learning community, and an Apple TV in every learning environment, allows them to share their learning and interact with guest speakers and teachers/students worldwide. The Technology Support Team ensures that technology is purposefully integrated into the curriculum to add value to every student's learning journey.
                    </div> */}

                    {/* <div className="quote_container mt40">
                        <img src="/images/icons/quote.png" alt="quote" />
                        <blockquote>
                            <p dir="ltr" >“Technology is nothing. What’s important is that you have a faith in people, that they’re basically good and smart, and if you give them tools, they’ll do wonderful things with them.”</p>
                            <p dir="ltr"><cite>Steve Jobs</cite></p>
                        </blockquote>
                    </div> */}

                    {/* <div className="faculty_spotlight mt40 outline">
                        <img src="/images/hero/about_main_page.jpeg" alt="faculty" />

                        <div className="more_details pd20">

                            <div className="title primary-text font16">
                                Technology in Primary School
                            </div>

                            <div className=" general-text font12 text-justify desc">
                                Our purpose-built facility enhances the programme by providing a space that is aesthetically pleasing, spacious and functional. The Arts centre has dedicated learning spaces for Visual Arts, Drama & Music.
                                <br />
                                Opportunity for learners to present and curate abound. They include class, grade and Primary School wide options. Performances such as a poetry slam, fortnightly community times, art exhibitions, dramatic performances, a talent show, choirs and classroom singing all add up to a programme that has meaning and is enthusiastically supported by the community.
                            </div>
                        </div>

                    </div> */}

                    {/* <div className="quote_container mt40">
                        <img src="/images/icons/quote.png" alt="quote" />
                        <blockquote>
                            <p dir="ltr" >“Technology is nothing. What’s important is that you have a faith in people, that they’re basically good and smart, and if you give them tools, they’ll do wonderful things with them.”</p>
                            <p dir="ltr"><cite>Marc Celo</cite></p>
                        </blockquote>
                    </div> */}

                    {/* <div className="faculty_spotlight mt40 outline">
                        <img src="/images/hero/about_main_page.jpeg" alt="faculty" />

                        <div className="more_details pd20">

                            <div className="title primary-text font16">
                                Technology in Primary School
                            </div>

                            <div className=" general-text font12 text-justify desc">
                                Our purpose-built facility enhances the programme by providing a space that is aesthetically pleasing, spacious and functional. The Arts centre has dedicated learning spaces for Visual Arts, Drama & Music.
                                <br />
                                Opportunity for learners to present and curate abound. They include class, grade and Primary School wide options. Performances such as a poetry slam, fortnightly community times, art exhibitions, dramatic performances, a talent show, choirs and classroom singing all add up to a programme that has meaning and is enthusiastically supported by the community.
                            </div>
                        </div>

                    </div> */}
                </div>
                <div className="right_side">
                    <BlogLine size={2} />
                </div>



            </PageStructure>

            <AcademicPartners />


        </div>



    )

}

export default EnrichmentTechnology;