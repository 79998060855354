import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import React, { useEffect, useState } from "react";

const ReactDropdown = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let arr = [];
    props.data &&
      props.data.forEach((val) => {
        arr.push({ ...val, label: val.name });
      });

    setData(arr);
  }, [props.data]);

  const onSelect = (e) => {
    const findItem = data.find((val) => val.name === e.label);
    props.onSelectChange(findItem);
  };

  return (
    <div className="react-dropdown-container mt20" style={{ flex: 1 }}>
      {props.label ? <div className={"label bold-text font10"}>{props.label} </div> : ""}
      <Dropdown
        options={data}
        onChange={onSelect}
        value={props.value}
        placeholder={props.noPlaceholder ? "" : props.placeholder}
        disabled={props.disabled}
        className={props.noBorder ? "border-less font14" : "font14"}
        style={props.style}
      />
      {props.error ? <div className="error font10 mt6">{props.error}</div> : null}
    </div>
  );
};

export default ReactDropdown;
