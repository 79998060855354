import React, { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import ContentContainer from "../../components/ContentContainer";
import SplashTitle from "../../components/SplashTiltle";
import AcademicPartners from "../../components/page-components/AcademicPartners";
import DolphinCard from "../../components/DolphinCard";
import { useRecoilState } from "recoil";
import { aboutPageTabsState } from "../../Recoil/atoms";
import GeneralTitle from "../../components/GeneralTitle";

const EnrichmentHome = () => {
  const [aboutTabs, setAboutTabs] = useRecoilState(aboutPageTabsState);
  const [sections, setSections] = useState([
    {
      id: 0,
      image: "/images/exp_items/tech.jpg",
      title: "Being the change by design",
      desc:
        "Making an innovative product/service that responds to a pressing need around them",
      active: true,
      link: "enrichment/arts"
    },
    {
      id: 1,
      image: "/images/exp_items/change_by_design.jpg",
      title: "Innovation in Practice",
      desc:
        "Practice-based project which involves learning new skills and getting their hands dirty in making what they collectively envision",
      active: false,
      link: "enrichment/technology"
    },
    {
      id: 2,
      image: "/images/exp_items/exchange.jpg",
      title: "Exposure in Exchange",
      desc:
        "Children travel to a different city, live with local host families/ hostels and attend a school as a regular student, learning in the process what words like co-existence, cultural plurality and empathy really mean",
      active: false,
      link: "enrichment/after_school_activities"
    },
    {
      id: 3,
      image: "/images/exp_items/well_being.jpg",
      title: "Well-being in Action",
      desc:
        "Personal and peer well-being through sports, martial arts, arts based therapy and mental-health counselling",
      active: false,
      link: "enrichment/sports"
    },

    {
      id: 4,
      image: "/images/exp_items/celebration.JPG",
      title: "Celebrations",
      desc:
        "Celebration of who we are, where we belong and what we do with film-makers, designers and explorers, who enjoy making richness visible through artistic media",
      active: false,
      link: "enrichment/celebrations"
    },
  ]);

  const [intro, setIntro] = useState(null)

  useEffect(() => {
    loadAboutCMS();
    window.scrollTo(0, 0);
  }, []);

  const loadAboutCMS = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/main_page_intros/enrichment",
      method: "GET",
      callback: (result) => {
        console.log({ result })
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {


          setIntro(result.data.data[0])

          // setAboutTabsData(result.data.data);
        }
      },
    });
  };

  return (
    <div className="aboutskuast-k-container">
      <HeroBanner name="Enrichment" image={"/images/Enrichmentheronew.jpg"} />

      <ContentContainer>
        <div className="about_container">
          <GeneralTitle title={"Enrichment"} />
          <div className="brief_intro card font12">
            With 5 exciting themes, in 2019 Dolphin launched its elective program for middle school
            and secondary classes to help students find a new horizon of rising possibilities and
            setting inhibitions by immersion, by practice, and by exposure. Each follows a theme and
            has two projects/activities led by visiting facilitators from across the globe. Students sign
            up for 1 theme a year and produce a presentable report at the end of the year for a jury.

          </div>

          <div className="more_sections_title">
            <GeneralTitle title={"In this section"} />
          </div>

          <div className="other_sections enrichment_sections">
            {sections?.map((section, index) => <DolphinCard item={section} index={index} tabs={aboutTabs} setTabs={setAboutTabs} />)}
          </div>

          <AcademicPartners />
        </div>
      </ContentContainer>
    </div>
  );
};
export default EnrichmentHome;
