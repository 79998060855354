const CommonServices = {


    changePage: (allTabs) => {

        const tabName = window?.location?.hash?.split("/")[2];
        const tabClicked = allTabs.find(i => i.link.includes(tabName))

        let newTabs = [];

        allTabs &&
            allTabs.length > 0 &&
            allTabs.map((oldTab) => {
                if (oldTab.id === tabClicked.id) {
                    newTabs.push({ ...oldTab, active: true });
                } else {
                    newTabs.push({ ...oldTab, active: false });
                }
            });

        return newTabs;
    },


    changeHeroImage: () => {

        const tabName = window?.location?.hash?.split("/")[2];

        switch (tabName) {
            case "about_dolphin":
                return "/images/hero/about-hero.JPG"
            case "leadership":
                return "/images/hero/leadership.jpg"
            case "guiding_statements":
                return "/images/hero/guiding_statements.jpeg"
            case "community":
                return "/images/hero/community.JPG";
            default:
                return "/images/hero/about-hero.JPG"
        }
    }
}


export default CommonServices;