import React from 'react'

const Blog = ({ tags, blog }) => {
  return (
    <div className="blog_item_wrapper">
      <div className="avatar"><img src={blog.image} alt="Blog Image" /></div>
      <div className="blog_title bold-text font16">{blog.title}</div>
      {/* <div className="author font14 bold-text">{blog.author}</div> */}
      <div className="date font10 bold-text">{new Date(blog.date).toLocaleDateString()}</div>
      <div className="blog_body text-justify " dangerouslySetInnerHTML={{ __html: blog?.desc?.substr(0, 300) + "..." }}></div>
      {tags ? <div className="tags">
        {tags.map((tag, index) => {
          return <span key={index} className="tag font8">{tag}</span>
        })}
      </div> : null}
    </div>
  )
}

export default Blog
