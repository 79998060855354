import { useEffect, useState } from "react";
import Video from "./Video";
import { callApi } from "../api/callApi";
import AppData from "../AppData";

const VideoLine = () => {
    const [videos, setVideos] = useState([])

    useEffect(() => {
        loadHomepageViedeos()
    }, [])


    const loadHomepageViedeos = () => {
        callApi({
            url: AppData.BASE_URL + "api/v1/gallery/video",
            method: "GET",
            callback: (result) => {
                if (
                    result &&
                    result.data &&
                    result.data.data &&
                    result.data.data.length > 0
                ) {
                    setVideos(result.data.data)
                }
            }
        })
    }
    return (
        <div className="video_wrap mt40">
            {/* <Video videoUrl={"https://resources.finalsite.net/videos/t_video_vp9_1080/v1625228715/stonehillinternationalschoolorg/z3cplipnif6xavh87rn1/School_Campus_Video.webm"} thumbnailUrl={"/images/vidback3.jpg"} title={"A Day In The Life Of PYP Students"} />
            <Video videoUrl={"https://resources.finalsite.net/videos/t_video_vp9_1080/v1625228715/stonehillinternationalschoolorg/z3cplipnif6xavh87rn1/School_Campus_Video.webm"} thumbnailUrl={"/images/vidback2.jpg"} title={"A Day In The Life Of PYP Students"} />
            <Video videoUrl={"https://resources.finalsite.net/videos/t_video_vp9_1080/v1625228715/stonehillinternationalschoolorg/z3cplipnif6xavh87rn1/School_Campus_Video.webm"} thumbnailUrl={"/images/vidback3.jpg"} title={"A Day In The Life Of PYP Students"} /> */}
            {videos?.map(video => <Video videoUrl={video.video_url} thumbnailUrl={"/images/vidback3.jpg"} title={video.title} />)}

        </div>
    )
}

export default VideoLine;