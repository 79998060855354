import { useRecoilValue } from "recoil"
import { objectivesState } from "../Recoil/atoms"

const Objectives = ({ objectives }) => {

    return (
        <div className="focus_and_objectives">
            {
                objectives?.map((item, key) => {
                    return key !== 6 && (
                        <div className="objective" key={item.id} >
                            <div className="font14 title" dangerouslySetInnerHTML={{ __html: item.title }} />
                            <div className="general-text font12" dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                    )
                })
            }
        </div >
    )
}


export default Objectives;