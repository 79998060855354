import React, { useEffect } from "react";
import PageStructure from "../../../components/PageStructure";
import { useRecoilState, useRecoilValue } from "recoil";
import { newsItemsState, objectivesState } from "../../../Recoil/atoms";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import BlogLine from "../../../components/BlogLine";
import AppData from "../../../AppData";
import { callApi } from "../../../api/callApi";

const ExploreNews = () => {
  const [newsItems, setNewsItems] = useRecoilState(newsItemsState);

  useEffect(() => {
    loadCMS();
    window.scrollTo(0, 0);
  }, []);

  const loadCMS = () => {
    callApi({
      url:
        AppData.BASE_URL + "api/v1/text_image/explore_dolphin/dolphin_in_media",
      method: "GET",
      callback: (result) => {
        console.log("result from news cms----", result);
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          setNewsItems(result.data.data);
        }
      },
    });
  };

  return (
    <div className="general_page">
      <div className="title font16 bold-text secondary-text font22">
        Dolphin in the Media
      </div>
      <PageStructure>
        <div className="left_side news_wrapper">
          {newsItems?.map((news, key) => (
            <NewsItem news={news} key={key} />
          ))}
        </div>
        <div className="right_side">
          <BlogLine />
        </div>
      </PageStructure>
      <AcademicPartners />
    </div>
  );
};

const NewsItem = ({ news, key }) => {
  return (
    <div className="news_item">
      <div className="image">
        <img src={AppData.BASE_URL + "/upload/cms/" + news.image} alt="image" />
      </div>
      <div className=" font12">{news.title}</div>
      <div className="date">{news.created_at?.split("T")[0]}</div>
      <div className="date">{news.pub}</div>
    </div>
  );
};
export default ExploreNews;
