import React, { useEffect, useState } from "react";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";
import { callApi } from "../../api/callApi";
import MyModal from "../../components/MyModal";
import { useRecoilState } from "recoil";
import { explorePageTabsState } from "../../Recoil/atoms";
import ExploreNews from "./sectons/news";
import ExploreEvents from "./sectons/events";
import ExplorePublications from "./sectons/publications";
import CommonServices from "../../utilities/commonServices";

const ExploreSections = () => {
  const [tabs, setTabs] = useRecoilState(explorePageTabsState);

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  //change tab state on route change
  useEffect(() => {
    setTabs(CommonServices.changePage(tabs));
  }, [window.location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);

    callApi({
      url: AppData.BASE_URL + "api/v1/cms/activities",
      method: "GET",
      callback: (result) => {
        if (result && result.data) {
        }
      },
    });
  }, []);

  return (
    <div>
      <HeroBanner
        name="News Updates and more..."
        image={"/images/news/news_back.webp"}
      />

      <div className="rest-page content-wrapper">
        <SingularTabs tabs={tabs} />

        <div>
          {tabs[0].active ? <ExploreNews /> : null}
          {tabs[1].active ? <ExploreEvents /> : null}
          {/* {tabs[2].active ? <ExplorePublications /> : null} */}
          {tabs[2].active ? <div>Coming soon...</div> : null}
        </div>
      </div>

      <MyModal
        open={showModal}
        title={currentItem && currentItem.title}
        desc={currentItem && currentItem.description}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default ExploreSections;
