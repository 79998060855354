import React from "react";
import PageStructure from "../../../components/PageStructure";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import BlogLine from "../../../components/BlogLine";
import GeneralTitle from "../../../components/GeneralTitle";

const EnrichmentAfterSchool = () => {


    const activities = [
        { id: 0, title: "Technology", desc: "For Example: Coding, Maker Club, Robotics Club, etc", image: "/images/tech_secondary.webp" },
        { id: 1, title: "Arts", desc: "For Example: Creative Writing, Dance, Drama, etc", image: "/images/vidback3.JPG" },
        { id: 2, title: "Sports", desc: "For Example: Football, Horse Riding, Taekwondo, etc", image: "/images/vidback.JPG" },
        { id: 3, title: "Service", desc: "For Example: Tree Planting, Elderly Care, etc", image: "/images/pta.webp" },
    ]




    const activity = (act, index) => {
        return (
            <div className="activity" onClick={() => router.push(link)} key={index}>
                <div className="title font16 secondary-text">
                    {act?.title}
                </div>

                <div className="activity_rest">
                    <img src={act?.image} alt="activity" />
                    <div className="primary-background white-text font 12 desc">
                        {act?.desc}
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="general_page">

            <div className="title bold-text secondary-text font22 mt30">
                <GeneralTitle title={"Exposure in Exchange"} />
            </div>

            <PageStructure>
                <div className="left_side page_content">

                    <div className="card mt40">
                        {/* <div className="title primary-text font16">
                            Exposure in Exchange
                        </div> */}

                        <div className="desc font12">
                            In the realm of educational exchange programs, the concept of exposure takes center stage, weaving a tapestry of experiences that go beyond the classroom. Exposure, in the context of student exchanges, encapsulates the depth of cultural immersion, personal growth, and academic enrichment that participants encounter. These programs, often marked by a reciprocal give-and-take, offer students the chance to broaden their perspectives, share ideas, and cultivate lasting connections with peers from diverse backgrounds. Dolphin has been organizing exchange programs where students travel to a different city, live with local host families and attend a school as a regular student, learning in the process what words like co-existence, cultural plurality and empathy really mean.
                        </div>

                    </div>

                    {/* <div className="desc font12">The Mental Health (ASA) Programme at Dolphin is designed to provide a range of learning engagements that enables children to be creative, confident and collaborative.The Mental Health (ASA) Programme at Dolphin is designed to provide a range of learning engagements that enables children to be creative, confident and collaborative.</div>

                    <div className="main_heading_intro mb50 font22 desc primary-text mt30">
                        The ASAs are grouped into four categories and are aligned with the school’s mission:
                    </div>

                    <div className="innner_section_grid">
                        {activities?.map((act, index) => activity(act, index))}
                    </div>

                    <div className="idesc font12 card secondary-card mt40">
                        <p>Various student-led clubs as well as external vendors allow for our Primary, Middle, and High School students to participate in areas that are of particular interest to them in order to enhance their overall educational experience.</p>

                        <p>In addition to the Mental Health, Dolphin has partnered with local industry leaders to provide opportunities for our 8th - 12th grade students to apply their Approaches To Learning (ATL) skills in real world situations.</p>

                        <p>The After School Activity Programme strives to provide an atmosphere where children can enjoy, grow, and learn to lead a balanced and healthy life.</p>
                    </div>




                    <div className="title primary-text font16 mt40">
                        Embassy International Riding School
                    </div>
                    <div className="faculty_spotlight  outline">
                        <img src="/images/hero/about_main_page.jpeg" alt="faculty" />

                        <div className="more_details pd20">



                            <div className=" general-text font12 text-justify desc">
                                Embassy International Riding School Bangalore (EIRS), is India’s premier horse riding school, run according to British Horse Society standards. The 240 acre campus is adjacent to Dolphin International School.<br></br>

                                Riding lessons for all levels, from beginner to advanced, conducted by qualified instructors and offered as extra-curricular activities.<br></br>
                                Pony Club activities for children of all ages.<br></br>
                                A range of leisure, entertainment and hospitality activities, including dining at Ai Cavalli, one of the city’s longstanding Italian restaurants widely known for its simple, rustic appeal and delicious home-style cooking.<br></br>
                            </div>
                        </div>

                    </div> */}
                </div>
                <div className="right_side">
                    <BlogLine size={1} />
                </div>



            </PageStructure>

            <AcademicPartners />


        </div>



    )

}

export default EnrichmentAfterSchool;