import "./App.css";
import React, { useEffect, useState } from "react";
import { RecoilRoot } from "recoil";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./style/style.scss";
import AboutSkuast from "./screens/AboutScreens/Default";
import AboutSections from "./screens/AboutSections/Default";
import Feedback from "./screens/Feedback/Default";
import Gallery from "./screens/Gallery/Default";
import ContactUs from "./screens/ContactUs/Default";
import Admissions from "./screens/Admissions/Default";
import AdmissionSections from "./screens/AdmissionSections/Default";
import LearningHome from "./screens/Learning/LearningHome";
import LearningSections from "./screens/LearningSections/Default";
import ExploreMainScreen from "./screens/ExploreMainScreen/ExploreMainScreen";
import ExploreSections from "./screens/ExploreSections/ExploreSections";
import Testpage from "./screens/general/Default";
import Blogs from "./screens/Blogs/Blogs";
import Blog from "./screens/Blogs/BlogPage";
import Alumni from './screens/alumni/Alumni'
import EnrichmentHome from './screens/Enrichment/Default'
import EnrichmentSections from './screens/EnrichmentSections/Default'
import AdmissionForm from './screens/AdmissionForm/Default'
import PartnersScreen from './screens/PartnesrScreen/PartnersScreen'

const Default = React.lazy(() => import("./screens/HomeScreen/Default.js"));
import Header from "./components/Header";
import Footer from "./components/Footer";
import PageNotFound from "./components/PageNotFound";
import { callApi } from "./api/callApi";
import AppData from "./AppData";
import StickyHeader from "./components/StickyHeader";
// Import and Initialize the FontAwesome Library
import { library } from '@fortawesome/fontawesome-svg-core';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import Video from "./components/Video";
import MobileMenu from "./components/MobileMenu";
import { Careers } from "./screens/Careers/Default.js";

// Add the icons you want to use to the library
library.add(faVideo);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  const [startupData, setStartupData] = useState({});
  const [details, setDetails] = useState({});
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    // loadStartupData();
    // getContactDetails();
  }, []);

  const handleScroll = () => {
    window.scrollY >= 120 ? setIsHeaderFixed(true) : setIsHeaderFixed(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const loadStartupData = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/settings",
      method: "GET",
      callback: (result) => {
        if (result && result.data) {
          setStartupData(result.data);
        }
      },
    });
  };

  const getContactDetails = () => {
    callApi({
      url: AppData.BASE_URL + "api/v1/contacts",
      method: "GET",
      callback: (result) => {
        if (result && result.data && result.data.data) {
          setDetails(result.data.data[0]);
        }
      },
    });
  };

  useEffect(() => {

    setScreenWidth(window.outerWidth)

  }, [window.outerWidth])

  return (
    <div>
      <RecoilRoot>
        {/* {startupData &&
          startupData.data &&
          startupData.data.maintance_mode == 1 ? ( */}
        <HashRouter>
          <React.Suspense fallback={loading}>
            {screenWidth < 900 ? <MobileMenu /> : isHeaderFixed ? (
              <StickyHeader props={""} />
            ) : (
              <StickyHeader props={""} />
              // <Header props={""} />
            )}
            <Switch>
              <Route
                exact
                path="/"
                name="Home"
                render={(props) => <Default {...props} />}
              />
              <Route
                exact
                path="/about-us"
                name="AboutSkuast"
                render={(props) => <AboutSkuast {...props} />}
              />

              <Route
                exact
                path="/about-us/:section"
                name="AboutSections"
                render={(props) => <AboutSections {...props} />}
              />

              <Route
                exact
                name="Admissions"
                path="/admissions"
                render={(props) => <Admissions {...props} />}
              />

              <Route
                exact
                name="Enquire"
                path="/admissions/:section"
                render={(props) => <AdmissionSections {...props} />}
              />

              <Route
                exact
                name="Admission Form"
                path="/admission-form"
                render={(props) => <AdmissionForm {...props} />}
              />
              <Route
                exact
                path="/gallery"
                name="GALLERY"
                render={(props) => <Gallery {...props} />}
              />

              <Route
                exact
                path="/contact-us"
                name="Contact Us"
                render={(props) => <ContactUs {...props} />}
              />

              <Route
                exact
                path="/feedback"
                name="Feedback"
                render={(props) => <Feedback {...props} />}
              />

              <Route
                exact
                name="Contact Us"
                path="/contactus"
                render={(props) => <ContactUs {...props} />}
              />

              <Route
                exact
                name="Learning"
                path="/learning"
                render={(props) => <LearningHome {...props} />}
              />
              <Route
                exact
                path="/learning/:section"
                name="Learning"
                render={(props) => <LearningSections {...props} />}
              />

              <Route
                exact
                name="ExploreDolphon"
                path="/explore-dolphin"
                render={(props) => <ExploreMainScreen {...props} />}
              />

              <Route
                exact
                name="ExploreDolphon"
                path="/explore-dolphin/:section"
                render={(props) => <ExploreSections {...props} />}
              />

              <Route
                exact
                name="test"
                path="/test"
                render={(props) => <Testpage {...props} />}
              />


              <Route
                exact
                name="Blogs"
                path="/blogs"
                render={(props) => <Blogs {...props} />}
              />
              <Route
                exact
                name="Blog"
                path="/blogs/:id"
                render={(props) => <Blog {...props} />}
              />

              <Route
                exact
                name="Blog"
                path="/alumni"
                render={(props) => <Alumni {...props} />}
              />

              <Route
                exact
                name="EnrichmentHome"
                path="/enrichment"
                render={(props) => <EnrichmentHome {...props} />}
              />

              <Route
                exact
                name="EnrichmentSections"
                path="/enrichment/:section"
                render={(props) => <EnrichmentSections {...props} />}
              />

              <Route
                exact
                name="PartnersScreen"
                path="/partners"
                render={(props) => <PartnersScreen {...props} />}
              />

              <Route
                exact
                name="Careers"
                path="/careers"
                render={(props) => <Careers {...props} />}
              />
              <Route component={PageNotFound} />
            </Switch>
            <Footer data={details} />
          </React.Suspense>
        </HashRouter>
        {/* ) : (
          <div className="maintenance-wrapper">
            <div className="bold-text heading font20">
              {startupData &&
                startupData.data &&
                startupData.data.maintance_heading}
            </div>

            <div className="description fot14">
              {startupData &&
                startupData.data &&
                startupData.data.maintance_description}
            </div>
          </div>
        )} */}
      </RecoilRoot>
    </div>
  );
}

export default App;
