import React, { useState } from 'react';
import MyInput from '../../components/my-input';
import MyButton from '../../components/my-button';
import ContentContainer from '../../components/ContentContainer';
import { callApi } from '../../api/callApi';
import AppData from '../../AppData';
import MyModal from '../../components/MyModal';

export const Careers = () => {
    const [name, setName] = useState({ val: '', error: '' });
    const [residence, setResidence] = useState({ val: '', error: '' });
    const [phoneNumber, setPhoneNumber] = useState({ val: '', error: '' });
    const [specialization, setSpecialization] = useState({ val: '', error: '' });
    const [experience, setExperience] = useState({ val: '', error: '' });
    const [salary, setSalary] = useState({ val: '', error: '' });
    const [noticePeriod, setNoticePeriod] = useState({ val: '', error: '' });
    const [modalData, setModalData] = useState({ show: false, title: "", description: "" })

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return
        }
        // Perform form submission logic here

        let data = {
            name: name.val,
            residence: residence.val,
            phone_number: phoneNumber.val,
            specialization: specialization.val,
            experience: experience.val,
            salary: salary.val,
            notice_period: noticePeriod.val,
        }


        callApi({
            url: AppData.BASE_URL + "api/v1/careers",
            method: "POST",
            callback: (res) => {

                console.log("res from admissions form----", res);
                if (res?.data && res?.data?.success) {

                    setModalData({ show: true, title: "Success", description: "We have received your request, we will get back to you soon!" })

                    setTimeout(() => {
                        history.push("/")
                    }, 3000);

                } else {
                    setModalData({ show: true, title: "Error", description: "Something went wrong" })
                }
            },
            data: data
        });


    };

    const validateForm = () => {
        let isValid = true;

        if (name.val.trim() === '') {
            setName({ ...name, error: 'Name is required' });
            isValid = false;
        } else {
            setName({ ...name, error: '' });
        }

        if (residence.val.trim() === '') {
            setResidence({ ...residence, error: 'Residence is required' });
            isValid = false;
        } else {
            setResidence({ ...residence, error: '' });
        }

        if (phoneNumber.val.trim() === '') {
            setPhoneNumber({ ...phoneNumber, error: 'Phone Number is required' });
            isValid = false;
        } else {
            setPhoneNumber({ ...phoneNumber, error: '' });
        }

        if (specialization.val.trim() === '') {
            setSpecialization({ ...specialization, error: 'Specialization is required' });
            isValid = false;
        } else {
            setSpecialization({ ...specialization, error: '' });
        }

        if (experience.val.trim() === '') {
            setExperience({ ...experience, error: 'Years of Experience is required' });
            isValid = false;
        } else {
            setExperience({ ...experience, error: '' });
        }

        if (salary.val.trim() === '') {
            setSalary({ ...salary, error: 'Expected Salary is required' });
            isValid = false;
        } else {
            setSalary({ ...salary, error: '' });
        }

        return isValid;
    };

    return (

        <ContentContainer>

            <div className='font12 text-center bold-text' style={{ paddingTop: 60, }}>Please complete all sections of this form in detail for the position that you are applying for. Incomplete forms will not be considered. Strong candidates will be called for interview. </div>
            <form onSubmit={handleSubmit} className='form_wrapper' style={{ paddingTop: 30, paddingBottom: 100 }}>

                <div className="inputs_row">
                    <MyInput
                        label="Name"
                        value={name.val}
                        onChange={(val) => setName({ ...name, val: val.target.value })}
                        error={name.error ? name.error : null}
                    />
                    <MyInput
                        label="Residence"
                        value={residence.val}
                        onChange={(val) => setResidence({ ...residence, val: val.target.value })}
                        error={residence.error ? residence.error : null}
                    />

                </div>

                <div className="inputs_row">
                    <MyInput
                        label="Phone Number"
                        value={phoneNumber.val}
                        onChange={(val) => setPhoneNumber({ ...phoneNumber, val: val.target.value })}
                        error={phoneNumber.error ? phoneNumber.error : null}
                    />
                    <MyInput
                        label="Specialization in Subject"
                        value={specialization.val}
                        onChange={(val) => setSpecialization({ ...specialization, val: val.target.value })}
                        error={specialization.error ? specialization.error : null}
                    />
                </div>

                <div className="inputs_row">
                    <MyInput
                        label="Years of Experience"
                        value={experience.val}
                        onChange={(val) => setExperience({ ...experience, val: val.target.value })}
                        error={experience.error ? experience.error : null}
                    />
                    <MyInput
                        label="Expected Salary"
                        value={salary.val}
                        onChange={(val) => setSalary({ ...salary, val: val.target.value })}
                        error={salary.error ? salary.error : null}
                    />
                    <MyInput
                        label="Notice Period (Optional)"
                        value={noticePeriod.val}
                        onChange={(val) => setNoticePeriod({ ...noticePeriod, val: val.target.value })}
                        error={noticePeriod.error ? noticePeriod.error : null}
                    />
                </div>


                {/* <button type="submit" disabled={() => !validateForm()}>
                Submit
            </button> */}

                <MyButton
                    style={{ borderRadius: 100, marginTop: "60px" }}
                    onClick={handleSubmit}
                    label="Submit"
                />


            </form>

            <MyModal
                open={modalData.show}
                title={modalData.title}
                desc={modalData.description}
                onClose={() => setModalData({ show: false })}
            />

        </ContentContainer>

    );
};
