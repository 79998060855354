import BlogLine from "../../../components/BlogLine";
import GeneralTitle from "../../../components/GeneralTitle";
import PageStructure from "../../../components/PageStructure";
import VideoLine from "../../../components/VideoLine";
import AcademicPartners from "../../../components/page-components/AcademicPartners";

const AboutCommunity = () => {


    return (
        <div className="general_page community_wrapper">

            <div className="title font16 bold-text secondary-text font22">
                <GeneralTitle title="Community" />
            </div>
            <PageStructure>
                <div className="left_side">
                    <GeneralTitle title={"We are a community of learners – students, teachers and parents"} />

                    <div className="card intro_slide white-text">
                        Dolphin is more than an institution; it's a thriving community where students, teachers, and parents weave a mosaic of shared values. With inquiry as one of our guiding principle, students are empowered to explore, question, and discover. Teachers, not just educators but architects of inspiration, nurture an atmosphere where learning extends beyond textbooks. Parents are integral collaborators, forming a partnership that enhances the educational journey. Inclusivity, interdependence, and a commitment to non-violence bind us together, fostering an environment where each achievement, big or small, is celebrated. Dolphin is a vibrant mosaic, rich in diversity, curiosity, and a collective commitment to growth.
                    </div>

                    <div className=" mt30">
                        <GeneralTitle title={"Faculty"} />
                    </div>

                    <div className="faculty_spotlight card mt20">
                        <img src="/images/faculty.jpeg" alt="faculty" />

                        <div className="faculty_spotlight_desc general-text font12 text-justify mt10">
                            The importance of teachers in any academic program, especially in the methodology of Dolphin, cannot be emphasized enough. Our faculty members at Dolphin are all trained, with many of them having previous experience in top schools at national level. This diversity among our faculty plays a crucial role in preparing students to adapt to a rapidly globalizing world. In fact, we have the most diverse community of teachers in Pulwama, with educators from various parts of India. Our teachers are committed to lifelong learning, and Dolphin invests in their continuous development through both local and international workshops and seminars for exposure and to provide a global platform to students and teachers also.
                        </div>

                    </div>

                    <div className=" mt30">
                        <GeneralTitle title={"Student Council"} />
                    </div>



                    <div className="faculty_spotlight mt20">
                        <img src="/images/student_council.jpeg" alt="faculty" />

                        <div className="cardless_desc general-text font12 text-justify white-text">
                            <p>
                                The Student Council at Dolphin is an essential component of our school community, consisting of a carefully elected group of student representatives. Led by the Head Boy and Head Girl, and supported by House Captains for each house, viz Lissodelphins Borealis, Turciops Truncatus, Orcinus Orca, and Stenella Clymene, this dynamic team totaling around 20 members, embodies the elected voices of the student body.
                            </p>
                            <p>
                                Elections for the Student Council are conducted through a democratic process, allowing students to choose their peers to take on crucial responsibilities within the school. Once elected, the council assumes a range of duties, including orchestrating school assemblies, maintaining discipline during key moments like lunch breaks and arrivals, and actively participating in the organization of various school events.
                            </p>
                            <p>
                                These student leaders are not only representatives but also serve as ambassadors of the student body, ensuring that the diverse perspectives and needs of their peers are heard and considered in shaping the school's policies and activities. The Student Council plays a vital role in fostering a sense of community, leadership, and responsibility among the student population, contributing significantly to the positive and engaging atmosphere that defines life at Dolphin.
                            </p>
                        </div>
                    </div>


                    <div className=" mt30">
                        <GeneralTitle title={"Parent Teacher Interaction"} />
                    </div>


                    <div className="faculty_spotlight mt20">
                        <img src="/images/pti.jpeg" alt="faculty" />

                        <div className="cardless_desc general-text font12 text-justify white-text">
                            <p>
                                At Dolphin School, we deeply value the active participation of parents in our school community. With a dedicated Public Relations Officer (PRO) overseeing parental concerns, we ensure effective communication and address any issues promptly.
                            </p>
                            <p>
                                Our engaged parents are welcomed to regular Parent-Teacher Interactions held after each assessment (approximately 5 times a year). During these sessions, parents gain valuable insights into their child's academic progress and overall development. This collaborative approach strengthens the bond between parents, teachers, and students, fostering a supportive environment for the child's educational journey.
                            </p>
                            <p>
                                In addition to formal interactions, parents maintain active contact with homeroom teachers, offering a platform to suggest changes, voice opinions, and actively contribute to shaping the educational experience. This open channel of communication ensures that parental perspectives are considered in the decision-making process, enhancing the overall effectiveness of our school policies.
                            </p>
                            <p>
                                At Dolphin School, we appreciate and encourage the vital role parents play in their child's education. The collaborative efforts of parents and educators create a nurturing environment where each student can thrive.
                            </p>
                        </div>
                    </div>

                    <div className=" mt30">
                        <GeneralTitle title={"Outreach and Community Engagement"} />
                    </div>

                    <div className="faculty_spotlight card mt20">
                        <img src="/images/outreach.JPG" alt="faculty" />

                        <div className="faculty_spotlight_desc general-text font12 text-justify mt10">
                            <p>
                                At Dolphin School, we hold a profound belief in the significance of outreach and community engagement. Our commitment to fostering learning extends beyond the walls of our classrooms, driving us to create impactful initiatives that positively influence the wider community.
                            </p>
                            <p>
                                In 2022, Dolphin School proudly inaugurated its Robotics Lab, equipped with cutting-edge technology to inspire innovation and creativity among our students. Recognizing the transformative potential of this resource, we were compelled to extend its benefits to the broader community.
                            </p>
                            <p>
                                Our Robotics Program is designed to bridge the gap between educational resources and aspiring young minds. To achieve this, we organize engaging workshops that invite children from various schools across Kashmir to delve into the world of robotics. These workshops serve as a platform for students to not only learn theoretical concepts but also to apply them in a hands-on, practical setting.
                            </p>
                            <p>
                                By opening our doors to students beyond our immediate school community, we aim to foster collaboration, curiosity, and a shared passion for robotics. This initiative not only provides valuable educational opportunities but also promotes a sense of unity and connectivity among diverse groups of learners.Through this initiative, Dolphin School continues to contribute to the growth of STEM education in the region and empower young minds to explore the limitless possibilities that technology offers.
                            </p>
                            <p>
                                As we strive to make a positive impact in the lives of those around us, Dolphin School remains committed to expanding our outreach programs and creating opportunities for learning that transcend traditional boundaries.
                            </p>
                        </div>

                    </div>

                </div>

                <div className="right_side">
                    <BlogLine size={8} />
                </div>
            </PageStructure>
            <VideoLine />
            <AcademicPartners />
        </div>
    )

}

export default AboutCommunity;