import React from "react";
import PageStructure from "../../../components/PageStructure";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import BlogLine from "../../../components/BlogLine";
import ShowMoreCard from "../../../components/ShowMoreCard";
import GeneralTitle from "../../../components/GeneralTitle";

const EnrichmentArts = () => {


    return (
        <div className="general_page">

            <div className="title bold-text secondary-text font22 mt30">
                <GeneralTitle title={"Celebrations"} />
            </div>
            <PageStructure>
                <div className="left_side page_content">

                    <div className="main_heading_intro mb50 font16 desc primary-text">
                        In the elective "Celebration" students dive into the elaborate design of human culture, examining the diverse ways societies commemorate events, achievements, and traditions. From festivals and ceremonies to personal milestones, this course explores the profound impact of celebrations on individuals and communities. Students not only learn the historical and cultural significance of various celebrations but also engage in practical activities, event planning, and creative projects. Through this dynamic exploration, students gain a deep appreciation for the art of celebration as a unifying force, fostering a sense of identity, belonging, and shared joy. Here we celebrate who we are, where we belong and what we do with film-makers, designers and explorers, who enjoy making richness visible through artistic media. Students in this elective shall go through a process of observing, discussing, creating, displaying and celebrating!
                    </div>

                    <div className="card">
                        <div className="title primary-text font16 pb0">
                            Kalakar qasba
                        </div>

                        <div className="desc font12">
                            Continuing the vision ever since, DIS has been conducting arts based therapy sessions with students in multiple formats, creating structured channels of expression for them. With the increase in political lockdowns and communication blockades since mid 2019 in Kashmir, the need for more rigorous work on mental well-being and social upliftment has risen. In view of this DIS collaborated with the University of West of England, Bristol and Arts Research Council through the artists Vikramjeet Sinha and Aurupa Roy to strengthen this foundation of mental health work in Pulwama. This collaboration intends a research based approach to create replicable work books towards the end of a 9 month long immersive program named as “Kalakar Qazba” with students, with experts in the field as mentors.
                        </div>

                    </div>

                    <div className="card mt40">
                        <div className="title primary-text font16 pb0">
                            “India and I”
                        </div>

                        <div className="desc font12">
                            India and I studies is set of fully scripted lesson plans for Grade 3-8 based on the four constitutional values of Liberty, Equality, Fraternity, and Justice, critical 21st century skilled, and social-emotional learning.
                        </div>

                    </div>

                    <div className="card mt40">
                        <div className="title primary-text font16 pb0">
                            Unfolding thru the arts
                        </div>

                        <div className="desc font12">
                            Dolphin International school started working with a mental health consultant on fivefold approach concerning important areas of psycho-social development: vulnerability, guidance, creativity, skill and belief. The Project was called ‘Unfolding through the arts’. The target groups were children from grade 6 to grade 10, who experience adolescent crisis and vulnerability. This stage of life is when children are greatly at risk. The intention is to transform belief from “I just exist”, to “I live”, “I dream”. To implement this vision practically DIS started working on two important projects: the first project, ‘unfolding with the arts’, related to Mental health and holistic wellbeing through the creative arts was launched in 2014.
                        </div>

                    </div>

                    {/* <React.Fragment>
                        <div className="title secondary-text font16 mt20">
                            Celebrations in Secondary School
                        </div>

                        <div className="secondary-background white-text pd10" style={{ padding: 30 }}>
                            <p className="font14 bold-text">Celebrations in Secondary</p>
                            <ul className="font14">
                                <li>Service in the Middle Years Programme</li>
                                <li>Creativity, Activity, Service (CAS) in the Diploma Programme</li>
                            </ul>
                        </div>
                    </React.Fragment> */}



                    {/* <div className="card mt40">
                        <div className="title secondary-text font16 mt20 pt0">
                            Service in the Middle Years Programme (MYP)
                        </div>

                        <div className="desc font14">
                            The Service programme at Dolphin is referred to as Dolphin in Action (SIA). Action and Service have always been shared values of the Dolphin community. The students take action when they apply what they are learning in the classroom and beyond. Dolphin students strive to be caring members of the community who demonstrate a commitment to service, making a positive difference to the lives of others and to the environment.
                        </div>

                        <div className="title secondary-text font14">
                            What is the aim of SIA?
                        </div>

                        <div className="desc font14 ">
                            SIA aims at developing globally competent and culturally aware students. The students progressively grow to become caring, open-minded, and courageous. They become advocates, who use their academic knowledge and skills to address genuine community needs.
                        </div>

                    </div> */}

                    {/* <ShowMoreCard title="How is SIA structured?" desc="SIA is part of the MYP curriculum and is based on the seventeen sustainable development goals set by the United Nations General Assembly. It is interconnected to the Learner Profile and Approaches to Learning (ATLs) set by the IB. Each MYP student is expected to initiate, plan and execute one individual project. " />

                    <ShowMoreCard title="What is the significance of SIA?" desc="The SIA students show perseverance and commitment by planning and initiating activities. They work collaboratively with others, increasing awareness of their own strengths and areas for growth. They take on challenges and develop new skills.  They consider the ethical implications of their actions while engaging with issues of local and global importance. It is expected that students will use their knowledge to make a positive difference to the world around them." />



                    <React.Fragment>

                        <div className="title secondary-text font16 mt40">
                            Creativity, Activity, Service (CAS) in the Diploma Programme (DP)
                        </div>

                        <div className="faculty_spotlight outline">
                            <img src="/images/hero/about_main_page.jpeg" alt="faculty" />

                            <div className="more_details pd20">

                                <div className=" general-text font12 text-justify desc">
                                    <p>Creativity, Activity, Service (CAS) is one of the three essential elements that every student must complete as part of the Diploma Programme (DP). Studied throughout the Diploma Programme, CAS involves the students in a range of activities alongside their academics. CAS has seven learning outcomes but it is not formally assessed. However, the students reflect on their CAS experiences as part of the DP, and provide evidence of achieving the seven learning outcomes.</p>

                                    <div className="bold-text pt10 pb10">
                                        Making a Difference at Dolphin
                                    </div>
                                    <p>The CAS programme at Dolphin is considered vital to the overall development of DP students. The programme is introduced by the coordinator at the beginning of D1 (Grade 11) through a series of slides, talks, videos and discussions. CAS runs for eighteen months and requires each student to understand their own strengths and work on further developing them.</p>
                                </div>
                            </div>

                        </div>
                    </React.Fragment>

                    <ShowMoreCard title="How is CAS structured?" desc="CAS is documented individually and the coordinator keeps in touch with each student.  Each student has a page where they upload their plans and reflections. The last requirement of the CAS programme is the group project. The students work in groups to create a product or host an event. " /> */}


                </div>
                <div className="right_side">
                    <BlogLine size={2} />
                </div>



            </PageStructure>

            <AcademicPartners />


        </div>



    )

}

export default EnrichmentArts;