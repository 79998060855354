import React, { useEffect, useState } from "react";
import GeneralTitle from "../../../components/GeneralTitle";
import PageStructure from "../../../components/PageStructure";
import Objectives from "../../../components/Objectives";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import BlogLine from "../../../components/BlogLine";
import { callApi } from "../../../api/callApi";
import AppData from "../../../AppData";
import VideoLine from "../../../components/VideoLine";
import { useHistory } from "react-router-dom";

const AboutDolphin = () => {
    const [pageIntro, setPageIntro] = useState([])
    // const [objectives, setObjectives] = useRecoilState(objectivesState)
    const [chairmanData, setChairmanData] = useState(null)
    const [academicPartners, setAcademicPartners] = useState([]);


    const router = useHistory();

    useEffect(() => {
        loadAboutUsAboutDolphin()
    }, [])

    useEffect(() => {

        let partnersLocal = [];
        callApi({
            url: AppData.BASE_URL + "api/v1/partners",
            method: "GET",
            callback: (result) => {
                if (
                    result &&
                    result.data &&
                    result.data.data &&
                    result.data.data.length > 0
                ) {
                    result.data.data.map((item) => {
                        partnersLocal.push({
                            id: item.id,
                            icon: AppData.BASE_URL + result.data.mediaPath + "/" + item.image,
                            link: item.link,
                            order_id: item.order_id,
                        });
                    });

                    setAcademicPartners(partnersLocal);
                }
            },
        });
    }, [])

    const loadAboutUsAboutDolphin = () => {
        callApi({
            url: AppData.BASE_URL + "api/v1/page_intros/about_us/about_dolphin",
            method: "GET",
            callback: (result) => {
                if (
                    result &&
                    result.data &&
                    result.data.data &&
                    result.data.data.length > 0
                ) {
                    setPageIntro(result.data.data.reverse())
                    // setObjectives(result.data.data.filter(item => item.id != 0 && item.id !== 6))
                }
            },
        });

        callApi({
            url: AppData.BASE_URL + "api/v1/profiles/faculty",
            method: "GET",
            callback: (result) => {
                // console.log({ result })
                if (result?.data?.data) {

                    let data = {
                        profile: result.data.data.find(it => it.name.includes("Farooq")),
                        mediaPath: result.data.mediaPath
                    }
                    setChairmanData(data)
                }
            },
        });
    };

    console.log("Alumini", { chairmanData });

    const gotoPartnersPage = () => {
        router.push("/partners")
    }

    return (
        <div className="general_page">

            <div className="bold-text secondary-text text-center mb50 mt50">
                <GeneralTitle title={"About Dolphin"} />
            </div>

            <PageStructure>
                <div className="left_side">
                    <GeneralTitle title={"Greetings and a warm welcome to Dolphin International School!"} />

                    <div className="page_content">
                        <div className="card">

                            {chairmanData?.profile && chairmanData?.profile?.name ?
                                <div className="left">

                                    <div className="img_name">
                                        <img src={`${AppData.BASE_URL}${chairmanData?.mediaPath}/${chairmanData?.profile?.image}`} alt="" />
                                        <div className="brief text-center">
                                            <div className="name font16 bold-text">
                                                {chairmanData?.profile?.name}
                                            </div>
                                            <div className="position font12" dangerouslySetInnerHTML={{ __html: chairmanData?.profile?.position ? `${chairmanData?.profile?.position.split(" ")[0]}<br>${chairmanData?.profile?.position.replace("Chairman", "")}` : null }}>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="right text-justify general-text" dangerouslySetInnerHTML={{ __html: chairmanData?.profile?.description }} />
                                </div> : null}
                        </div>

                        {/* <React.Fragment >
                            <div style={{ marginTop: 20 }}>
                                <div className="title primary-text font16">
                                    A Short History of Dolphin International School
                                </div>

                                <div className="card general-text" dangerouslySetInnerHTML={{ __html: pageIntro[1]?.description }} />
                            </div>
                        </React.Fragment> */}

                        <div style={{ marginTop: 20 }} className="academic_partners_about_parent card">
                            <div className="title primary-text font16">
                                ACADEMIC PARTNERS AND MEMBERSHIP
                            </div>

                            <div className="general-text academic_partners_about" >
                                {academicPartners && academicPartners.length && academicPartners?.sort((a, b) => a.order_id - b.order_id)?.map((partner, index) => (
                                    <Partner partner={partner} index={index} />
                                ))}
                            </div>

                            <div className="title primary-text font16 flex-end-text" onClick={gotoPartnersPage}>
                                See More...
                            </div>


                        </div>


                        <React.Fragment >


                        </React.Fragment>

                        <React.Fragment >

                            <div style={{ marginTop: 20 }}>
                                <div className="title primary-text font16">
                                    STRATEGIC PLAN - AREAS OF STRATEGIC FOCUS AND OBJECTIVES
                                </div>

                                <Objectives objectives={pageIntro} />
                            </div>
                        </React.Fragment>


                    </div>
                </div>
                <div className="right_side">
                    <BlogLine />
                </div>



            </PageStructure>

            <VideoLine />

            <AcademicPartners />


        </div>



    )

}


function Partner({ partner, index }) {
    console.log({ partner })
    return (
        <div className="partner" key={index} onClick={() => window.open(partner.link, "_blank")}>
            <img src={partner.icon} alt="" />
        </div>
    );
}

// const objectives = [
//     { id: 0, title: "Learning and Teaching", desc: "Maximise and enhance every student’s potential by emphasising a personalised learning environment throughout the school based on the latest pedagogical research." },
//     {
//         id: 1, title: "Infrastructure, School Growth and Development", desc: "Develop flexible and responsive systems and a culture of adaptability and innovation to establish Dolphin as a school of choice in India."
//     },
//     { id: 2, title: "Well-Being and Child Protection", desc: "Create the best physical and emotional environment to ensure the safety and well-being of all members of the community" },
//     { id: 3, title: "Governance", desc: "Ensure effective leadership which is modeled on the duty of care, learning, trust, and accountability, that best serves the school now and in the future." },
//     { id: 4, title: "Financial Sustainability", desc: "Responsible planning and management of resources to be financially stable and self-sustaining." },
//     { id: 5, title: "Identity", desc: "Build our reputation and be recognised as a world- class international school in India with a clear and compelling identity." },
// ]

export default AboutDolphin;