import { useEffect, useState } from "react";
import DolphinCard from "../../../components/DolphinCard";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import { callApi } from "../../../api/callApi";
import AppData from "../../../AppData";
import VideoLine from "../../../components/VideoLine";
import SplashTitle from "../../../components/SplashTiltle";
import GeneralTitle from "../../../components/GeneralTitle";

const AboutLeadership = () => {

    const [gcs, setGCS] = useState([]);
    const [bods, setBODS] = useState([])
    const [pageIntro, setPageIntro] = useState([])


    useEffect(() => {
        window.scrollTo(0, 0)
        loadAboutUsLeadership()
    }, [])

    const loadAboutUsLeadership = () => {
        callApi({
            url: AppData.BASE_URL + "api/v1/page_intros/about_us/leadership",
            method: "GET",
            callback: (result) => {
                if (result?.data?.data?.length > 0) {
                    setPageIntro(result.data.data.reverse())
                }
            },
        });
    };

    useEffect(() => {
        let gcsLocal = [];
        callApi({
            url: AppData.BASE_URL + "api/v1/profiles/GC",
            method: "GET",
            callback: (result) => {
                if (result?.data?.data) {
                    gcsLocal = result?.data?.data?.map(it => {
                        return {
                            ...it, image: AppData.BASE_URL + result?.data.mediaPath + "/" + it.image, desc: it.position, about: it.description, title: it.name
                        }
                    })
                    setGCS(gcsLocal)
                }
            },
        });
    }, []);

    useEffect(() => {
        let bodLocal = [];
        callApi({
            url: AppData.BASE_URL + "api/v1/profiles/BOD",
            method: "GET",
            callback: (result) => {
                if (result?.data?.data) {
                    bodLocal = result?.data?.data?.map(it => {
                        return {
                            ...it, image: AppData.BASE_URL + result?.data.mediaPath + "/" + it.image, desc: it.position, about: it.description, title: it.name
                        }
                    })
                    setBODS(bodLocal)
                }
            },
        });
    }, []);


    return (
        <div className="general_page">
            <div className="title font16 bold-text secondary-text font22">
                <GeneralTitle title={"Leadership"} />
            </div>

            <div className="brief_intro general-text font12 text-justify" dangerouslySetInnerHTML={{ __html: pageIntro[0]?.description }} />


            {/* <div className="title primary-text font18 mt40">
                Executive Members of the Governing Council (GC)
            </div> */}
            <div className="mt50">
                <GeneralTitle title={"Governing Council (GC)"} />
            </div>

            <div className="other_sections leadership_sections">
                {gcs?.sort((a, b) => a.id - b.id)?.map(member => {
                    return <DolphinCard index={member.id} item={member} extraSection={true} />
                })}

            </div>

            {/* <div className="title secondary-text font18 mt40 uppercase">
                Board of Advisors
            </div> */}
            <div className="mt50">
                <GeneralTitle title={"Board of Advisors"} />
            </div>

            <div className="other_sections leadership_sections">
                {bods?.sort((a, b) => a.id - b.id)?.map(member => {
                    return <DolphinCard index={member.id} item={member} extraSection={true} hideDescription />
                })}

            </div>

            <VideoLine />

            <AcademicPartners />




        </div>
    )

}

export default AboutLeadership;