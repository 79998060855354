import React from "react";
import { useHistory } from "react-router-dom";
import { FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { ImLocation2 } from "react-icons/im";

function Footer(props) {
  const history = useHistory();
  const navigateRoute = (screen) => {
    history.push(screen);
  };

  return (
    <div className="footer_parient_container">
      {/* <div className="bc_img">
        <img src="/images/footerBackground.webp" alt="" />
      </div> */}
      <div className="contact_details_wrapper content-wrapper">
        <div className="contact_details_top">
          <div className="dolphin_log" onClick={() => navigateRoute("/")}>
            <img src="/images/logo2.jpg" alt="" />
            {/* <div className="dolphin_name">Dolphin International School</div> */}
          </div>
          <div className="dolphin_address">
            <div className="name_value">
              <div className="name">Address:</div>
              <div className="value">
                <p>Tengpuna Pulwama,</p> <p>Jammu and Kashmir,</p> <p>192301</p>
              </div>
            </div>
            {/* <div className="name_value">
              <div className="value">Tengpuna, Jammu and Kashmir 192301</div>
            </div> */}
          </div>
          <div className="dolphin_contact_us">
            <div className="name_value">
              <div className="name">Front office:</div>
              <div className="value">+91 9906944622</div>
            </div>
            <div className="name_value">
              <div className="name">Help desk:</div>
              <div className="value"> +91 9797722202</div>
            </div>
            <div className="name_value">
              <div className="name">Other queries:</div>
              <div className="value">
                <div>dolphin.in.schl@gmail.com</div>
                <div>info@dolphinschool.edu.in</div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact_details_bottom">
          <div className="call" onClick={() => navigateRoute("/contact-us")}>
            <span>
              <IoMdCall />
            </span>
            <span>Contact us</span>
          </div>
          <div
            className="dirrection"
            onClick={() => navigateRoute("/contact-us")}
          >
            <span>
              <ImLocation2 />
            </span>
            <span>Get Directions</span>
          </div>
          <div className="social_links">
            <span
              onClick={() =>
                window.open(
                  props.data.facebook ||
                    "https://www.facebook.com/dolphinpulwama",
                  "_blank"
                )
              }
            >
              {/* <FaFacebook fontSize={30} /> */}
              <img src="/images/social/facebook.png" alt="" />
            </span>
            <span
              onClick={() =>
                window.open(
                  props.data.youtube ||
                    "https://www.youtube.com/@dolphininternationalschool3098",
                  "_blank"
                )
              }
            >
              {" "}
              <img src="/images/social/youtube.png" alt="" />
              {/* <FaYoutube fontSize={30} /> */}
            </span>
            <span
              onClick={() =>
                window.open(
                  props.data.instagram ||
                    "https://www.instagram.com/dolphinschoolofficial",
                  "_blank"
                )
              }
            >
              <img src="/images/social/insta.png" alt="" />
              {/* <FaInstagram fontSize={30} /> */}
            </span>
          </div>
        </div>
      </div>

      <div className="developed_and_managed_by_section">
        <div
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=919596263439",
              "_blank"
            )
          }
          className="name text-center"
        >
          Developed and Managed with ❤️ by Bracecodes Software Solutions Pvt.
          Ltd.
        </div>
      </div>
    </div>
  );
}

export default Footer;
