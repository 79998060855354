import ContentContainer from "../ContentContainer";
import { useEffect, useState } from "react";
import { callApi } from "../../api/callApi";
import AppData from "../../AppData";

const AcademicPartners = () => {
  const [academicPartners, setAcademicPartners] = useState([]);


  useEffect(() => {

    let partnersLocal = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/partners",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          result.data.data.map((item) => {
            partnersLocal.push({
              id: item.id,
              icon: AppData.BASE_URL + result.data.mediaPath + "/" + item.image,
              link: item.link,
              order_id: item.order_id,
              title: item.title
            });
          });

          setAcademicPartners(partnersLocal);
        }
      },
    });
  }, []);

  console.log({ academicPartners })


  return (
    <ContentContainer>
      <div className="academic_partners">
        {academicPartners && academicPartners.length && academicPartners?.sort((a, b) => a.order_id - b.order_id)?.map((partner, index) => (
          <Partner partner={partner} index={index} />
        ))}
      </div>
    </ContentContainer>
  );
};

function Partner({ partner, index }) {
  return (
    <div className="partner" key={index} onClick={() => window.open(partner.link, "_blank")}>
      <img src={partner.icon} alt="" />
    </div>
  );
}

export default AcademicPartners;
