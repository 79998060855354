import React, { useEffect, useState } from 'react'
import GeneralTitle from '../../components/GeneralTitle';
import ContentContainer from '../../components/ContentContainer';
import { callApi } from '../../api/callApi';
import AppData from '../../AppData';

const PartnersScreen = () => {

  const [academicPartners, setAcademicPartners] = useState([]);


  useEffect(() => {

    let partnersLocal = [];
    callApi({
      url: AppData.BASE_URL + "api/v1/partners",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.length > 0
        ) {
          result.data.data.map((item) => {
            partnersLocal.push({
              id: item.id,
              icon: AppData.BASE_URL + result.data.mediaPath + "/" + item.image,
              link: item.link,
              order_id: item.order_id,
              title: item.title
            });
          });

          setAcademicPartners(partnersLocal);
        }
      },
    });
  }, [])

  console.log({ academicPartners })
  return (
    <div className="general_page">

      <ContentContainer>
        {/* <PageStructure> */}
        <div className="left_side mt50">
          <GeneralTitle title={"ACADEMIC PARTNERS & MEMBERSHIP"} />

        </div>

        {academicPartners && academicPartners.length > 0 && academicPartners?.sort((a, b) => a.order_id - b.order_id)?.map((item, index) => {

          return (
            <div className='partner_item card' style={styles.item_wrapper}>

              <div style={styles.img_container}>
                <img style={styles.img_item} src={item.icon} alt="" />

              </div>

              <div className='font13'>{item.title}</div>
            </div>
          )
        })}

        {/* </PageStructure> */}

      </ContentContainer>


    </div>
  )
}

const styles = {

  item_wrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: 200,
    marginBottom: 40,
  },

  img_container: {
    width: "30%",
    minWidth: 300,
    // backgroundColor: "red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img_item: {
    width: 200,
    height: "auto",
    objectFit: "contain"


  }

}

export default PartnersScreen;