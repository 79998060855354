import React, { useEffect, useState } from "react";
import AppData from "../../AppData";
import HeroBanner from "../../components/HeroBanner";
import SingularTabs from "../../components/SingularTabs";
import { callApi } from "../../api/callApi";
import MyModal from "../../components/MyModal";
import { useRecoilState } from "recoil";
import { learningPageTabsState } from "../../Recoil/atoms";
import LearningFoundation from "./sectons/foundation";
import LearningPrepatory from "./sectons/prepatory";
import LearningMiddle from "./sectons/middle";
import LearningSecondary from "./sectons/secondary";
import CommonServices from "../../utilities/commonServices";

const Default = () => {
  const [tabs, setTabs] = useRecoilState(learningPageTabsState);

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  //change tab state on route change
  useEffect(() => {
    setTabs(CommonServices.changePage(tabs))
  }, [window.location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);

    callApi({
      url: AppData.BASE_URL + "api/v1/cms/activities",
      method: "GET",
      callback: (result) => {
        if (
          result &&
          result.data
        ) {

        }
      },
    });
  }, []);

  return (
    <div>
      <HeroBanner name="More about us..." />

      <div className="rest-page content-wrapper">
        <SingularTabs tabs={tabs} />

        <div>

          {tabs[0].active ? <LearningFoundation /> : null}
          {tabs[1].active ? <LearningPrepatory /> : null}
          {tabs[2].active ? <LearningMiddle /> : null}
          {tabs[3].active ? <LearningSecondary /> : null}
        </div>
      </div>

      <MyModal
        open={showModal}
        title={currentItem && currentItem.title}
        desc={currentItem && currentItem.description}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default Default;
