import React, { Fragment, useState } from "react";
import ContentContainer from "../../../components/ContentContainer";
import { useHistory } from "react-router-dom";
import BlogLine from "../../../components/BlogLine";
import Appointment from "../../../components/Appointment";
import SplashTitle from "../../../components/SplashTiltle";
import PageStructure from "../../../components/PageStructure";
import VideoLine from "../../../components/VideoLine";
import Prospect from "../../../components/admissions/Prospect";
import AcademicPartners from "../../../components/page-components/AcademicPartners";
import GeneralTitle from "../../../components/GeneralTitle";

const ApplyIntoScreen = () => {
    const history = useHistory();
    const goto = (screen) => {
        history.push(screen);
    };


    const ApplyBox = () => {

        return (
            // <div className="submit_form_hyperlink mt50" onClick={() => history.push("/admission-form")}>
            <div className="submit_form_hyperlink mt50" onClick={() => window.open("https://app.educian.com/dolphin/externalEnquiry/?secController=externalEnquiry&action=enquiryFormView&tid=63be4c072957000080005018", "_blank")}>
                <div className="submit_form_hyperlink_inner secondary-background text-center white-text font16">
                    <span className="bold-text font14">
                        APPLY
                    </span>
                    <span className="font12 bold-text mt10">Click here to fill the registration form</span>
                </div>

            </div>
        )
    }

    return (
        <div className="general_page">
            <ContentContainer>
                <GeneralTitle title="Registration" />

                <PageStructure>
                    <div className="left_side page_content">
                        <div className="faculty_spotlight mt0 outline">
                            <img src="/images/hero/about-hero.JPG" alt="faculty" />

                            <div className="more_details pd20">
                                <div className=" general-text font12 text-justify desc">
                                    <p className="mt10">Once you have made the decision to apply to Dolphin, please fill in the Online Registration Form. </p>
                                    {/* <p className="mt10 bold-text">Checklist for supporting documents:</p>
                                    <ul className="mt10 ml30 font12">
                                        <li>Report cards for the last two years - copy of passport or birth certificate (copy of OCI/PIO card, if applicable)</li>
                                        <li>Copy of Vaccination Record</li>
                                        <li>A non-refundable Application Fee of US$ 150 (per child) is required in order to process the application. Details will be sent via email</li>
                                    </ul> */}
                                    {/* <p className="mt10">Additional documents may be required based on the application form and supporting documents submitted at the time of admission.</p> */}
                                </div>
                            </div>
                        </div>

                        <ApplyBox />

                        <div className="secondary-text title font16 mt40">Points to note from our Admissions Policy</div>

                        <div className="card secondary-card mt20">
                            <div className="secondary-text font14 bold-text">Eligibility</div>

                            <ul className="mt20 ml20 font12">
                                All students are eligible for admission if it is believed that the school can provide an educational programme that can meet their particular needs. We are staffed to enable learning support to a limited number of students with mild to moderate learning disabilities
                            </ul>

                            <div className="secondary-text font14 bold-text mt40">Class Placement</div>

                            <div className="font12 mt10">Children entering Dolphin will be placed according to their age. In Middle Years and High School an exception may be made based on a child’s educational history. Assessment is based on a combination of age, previous educational record, tests taken at the time of admission and classroom observation. The decision of the Admissions Committee is communicated via email.</div>

                        </div>


                    </div>
                    <div className="right_side">
                        <BlogLine size={2} />
                    </div>

                </PageStructure>

                <VideoLine />
            </ContentContainer>

            <AcademicPartners />
        </div>
    );
};




export default ApplyIntoScreen;
