import React from "react";

const Prospect = (props) => {
  return (
    <section className="mt50" id="" onClick={() => window.open("https://drive.google.com/file/d/1cySFiwQRzY9iIxpJkXQwMApOJ2s8O0lG/view?usp=sharing", "_blank")}>

      <div className="primary-text bold-text font16">
        Click on the image for school prospectus!
      </div>
      <div className="fsElementContent">
        <img alt="Prospect of school" src="/images/admission.JPG" />
      </div>
    </section>
  );
};

export default Prospect;
