import BlogLine from "../../../components/BlogLine";
import GeneralTitle from "../../../components/GeneralTitle";
import PageStructure from "../../../components/PageStructure";
import VideoLine from "../../../components/VideoLine";
import AcademicPartners from "../../../components/page-components/AcademicPartners";

const AboutGuidingStatements = () => {

    const statements = [
        { id: 0, icon: "/images/statements/dialogue.jpeg", title: "Dialogue", points: ["We learn through challenging, relevant, significant and engaging curricula.", "We create inspiring, innovative, and differentiated learning experiences.", "We share our understanding and apply knowledge to real-life situations.", "We maximize our academic potential.", "We understand that balance is essential for a healthy and fulfilling life"] },
        { id: 1, icon: "/images/statements/empowerment.jpeg", title: "Empowerment", points: ["We learn through challenging, relevant, significant and engaging curricula.", "We create inspiring, innovative, and differentiated learning experiences.", "We share our understanding and apply knowledge to real-life situations.", "We maximize our academic potential.", "We understand that balance is essential for a healthy and fulfilling life"] },
        { id: 2, icon: "/images/statements/enquiry.jpeg", title: "Enquiry", points: ["We learn through challenging, relevant, significant and engaging curricula.", "We create inspiring, innovative, and differentiated learning experiences.", "We share our understanding and apply knowledge to real-life situations.", "We maximize our academic potential.", "We understand that balance is essential for a healthy and fulfilling life"] },
        { id: 3, icon: "/images/statements/equity_fraternity.jpeg", title: "Equity Fraternity", points: ["We learn through challenging, relevant, significant and engaging curricula.", "We create inspiring, innovative, and differentiated learning experiences.", "We share our understanding and apply knowledge to real-life situations.", "We maximize our academic potential.", "We understand that balance is essential for a healthy and fulfilling life"] },
        { id: 0, icon: "/images/statements/inclusivity.jpeg", title: "Inclusivity", points: ["We learn through challenging, relevant, significant and engaging curricula.", "We create inspiring, innovative, and differentiated learning experiences.", "We share our understanding and apply knowledge to real-life situations.", "We maximize our academic potential.", "We understand that balance is essential for a healthy and fulfilling life"] },
        { id: 1, icon: "/images/statements/interdependence.jpeg", title: "Interdependence", points: ["We learn through challenging, relevant, significant and engaging curricula.", "We create inspiring, innovative, and differentiated learning experiences.", "We share our understanding and apply knowledge to real-life situations.", "We maximize our academic potential.", "We understand that balance is essential for a healthy and fulfilling life"] },
        { id: 2, icon: "/images/statements/reimagine.jpeg", title: "Non Violence", points: ["We learn through challenging, relevant, significant and engaging curricula.", "We create inspiring, innovative, and differentiated learning experiences.", "We share our understanding and apply knowledge to real-life situations.", "We maximize our academic potential.", "We understand that balance is essential for a healthy and fulfilling life"] },
        { id: 3, icon: "/images/statements/non_violence.jpeg", title: "Reimagine", points: ["We learn through challenging, relevant, significant and engaging curricula.", "We create inspiring, innovative, and differentiated learning experiences.", "We share our understanding and apply knowledge to real-life situations.", "We maximize our academic potential.", "We understand that balance is essential for a healthy and fulfilling life"] }
    ];


    return (
        <div className="general_page guiding_statements">
            <div className="title font16 bold-text secondary-text font22">Guiding Statements</div>

            <div className="card uppercase mb50">
                <div className="title font16 bold-text secondary-text font22 text-center mt20">MOTTO</div>

                <ul className="uppercase">
                    <p className="font12 font12 bold-text text-center uppercase">To cultivate a firm belief in present generation, educated by the society, that ‘NO NATION CAN RISE ABOVE THE LEVEL OF ITS TEACHER.’</p>
                    <p className="font12 font12 bold-text text-center">To create environmental awareness among the public in general, and youth in particular. To facilitate this motto, Sheikh Noor-u-din Noorani, scholar and great saint of valley, said that the life will survive on earth, as long as forests survive.</p>
                </ul>

            </div>
            {/* <div className="title font16 bold-text secondary-text font22 text-center mt20">VISION</div>
            <div className="text-center bold-text font12">
                WE ARE AN INTERNATIONALLY-MINDED COMMUNITY OF SPIRITED LEARNERS, DEDICATED TO THE DEVELOPMENT OF KNOWLEDGEABLE, CARING, CONFIDENT AND RESPONSIBLE GLOBAL CITIZENS.
            </div> */}
            <GeneralTitle title={"MISSION"} />
            {/* <div className="title font16 bold-text secondary-text font22 text-center mt20">MISSION</div> */}
            {/* <div className="text-center bold-text font12">
                OUR MISSION IS TO PROVIDE STIMULATING, ENGAGING ACADEMICS INTEGRATED WITH ENHANCED OPPORTUNITIES FOR TECHNOLOGICAL INNOVATION, SPORTS AND THE ARTS.
            </div> */}

            <div className="vision_wrapper mb40">
                {statements?.map(statement => {
                    return (
                        <div className="vision_item card">

                            <div className="icon_wrapper">
                                <img src={statement.icon} alt="" />
                            </div>

                            <div className="vision_title bold-text font16 text-center ">
                                {statement.title}
                            </div>

                            {/* <div className="vision_sub_points">
                                {statement?.points?.map(point => {
                                    return (
                                        <li className="point font11">{point}</li>
                                    )
                                })}
                            </div> */}
                        </div>
                    )
                })}

            </div>


            {/* <div className="title font16 bold-text secondary-text font22 mt50">Dolphin’s grid of vision, cultures, and choices</div> */}




            <GeneralTitle title={"Dolphin’s grid of vision, cultures, and choices"} />
            <div className="font13 mt20 dis_grid card ">

                <p>
                    In order to cater more inclusively to all its students, the school needed to rethink its policies and design a framework to enable the transformation it envisioned in its children. The following framework is a grid designed by the school to organise the way in which it made choices in service of curricular, spatial and behavioural change. The right side of the grid indicates the long-term macro character strengths that Dolphin aims as developing in a student by the end of her/his schooling. The columns indicate the cultures created collectively and consciously on campus that would eventually feed into building the macro goals. Hence, any project, programme, policy or structural addition at Dolphin helps in building a specific culture and further feeds into the overall character growth.
                </p>

                <p>
                    The character strengths chosen as macro goals rest upon a collective vision of the school management towards building strong individuals, who can stand tall against influences, and make wilful decisions, while being mindful of what their strengths and shortcomings are and being comfortable with selves and their surroundings. Dolphin also envisions its students to be curious, push boundaries of learning and explore the unknown across the globe, while finding strong roots in their contexts. Dolphin insists to build in its students the spirit of creative risk taking and constructive resilience, while negotiating their place in the world as stakeholders in responsible citizenship. The choices made by school as mentioned in the grid were categorised on three areas of school development:

                    <ul className="ml30 mt20" style={{ marginLeft: 30 }}>
                        <li>
                            <strong>Behavioural:</strong> pertaining to behaviour shifts among stakeholders to build conducive cultures

                        </li>
                        <li>
                            <strong> Spatial:</strong> building or re-arrangement of physical spaces and elements to allow culture building
                        </li>
                        <li>
                            <strong>Academic:</strong> changes in academic pursuits and pedagogies to integrate culture building with school curriculum. The following figure shows Dolphin’s grid of vision.
                        </li>
                    </ul>
                </p>

                <div className="text-center vision_center mt0">
                    <img src="/images/dis_grid.jpeg" alt="" />
                </div>

                <p className="mt40">
                    The grid helped Dolphin aim at “time-tabling for building character”, making activities for character building find their way in students’ daily academic routines, legitimising for all stakeholders the emphasis on culture-making and creative pursuits on campus. The grid further helped the school devise themes for interventions keeping in view student ages in the following progression:
                    <ul style={{ marginTop: 20, marginLeft: 30 }}>
                        <li>
                            Self and Surrounding (6-8)
                        </li>
                        <li>Citizenship (9-11)</li>
                        <li>My school my role (12-14)</li>
                        <li>Inner and outer worlds - awareness and response (15-16)</li>
                    </ul>
                </p>
            </div>



            <VideoLine />

            <AcademicPartners />


        </div>
    )
}

export default AboutGuidingStatements;