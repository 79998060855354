import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useHistory } from "react-router-dom";

function MobileMenu() {
  const history = useHistory();

  const [menu, setMenu] = useState([
    {
      title: "About us",
      screen: "/about-us",
      active: false,
      subMenu: [
        {
          name: "About Dolphin",
          screen: "/about-us/about_dolphin",
        },
        {
          name: "Leadership",
          screen: "/about-us/leadership",
        },
        {
          name: "Guiding Statements",
          screen: "/about-us/guiding_statements",
        },
        {
          name: "Community",
          screen: "/about-us/community",
        },
        {
          name: "Disclosere",
          screen: "/about-us/disclosere",
        },
      ],
    },

    {
      title: "Admissions",
      screen: "/admissions",
      active: false,
      subMenu: [
        {
          name: "Enquire",
          screen: "/admissions/enquire",
        },
        {
          name: "Registration",
          screen: "/admissions/apply",
        },
      ],
    },
    {
      title: "Learning",
      screen: "/learning",
      active: false,
      subMenu: [
        {
          name: "Foundation Stage",
          screen: "/learning/foundation",
        },
        {
          name: "Preparatory Stage",
          screen: "/learning/preparatory",
        },
        {
          name: "Middle Stage",
          screen: "/learning/middle",
        },
        {
          name: "Secondary Stage",
          screen: "/learning/secondary",
        },
      ],
    },

    {
      title: "Thematic Vision",
      screen: "/enrichment",
      active: false,
      subMenu: [
        {
          name: "Being the change by design",
          screen: "/enrichment/arts",
        },
        {
          name: "Innovation in Practice",
          screen: "/enrichment/technology",
        },
        {
          name: "Exposure in Exchange",
          screen: "/enrichment/sports",
        },
        {
          name: "Well being in Action",
          screen: "/enrichment/after_school_activities",
        },
        {
          name: "Celebrations",
          screen: "/enrichment/celebrations",
        },
        // {
        //   name: "Primary School Trips",
        //   screen: "/enrichment/primary_school_trips",
        // },
        // {
        //   name: "Secondary School Trips",
        //   screen: "/enrichment/secondary_school_trips",
        // },
      ],
    },
    {
      title: "Explore Dolphin",
      screen: "/explore-dolphin",
      active: false,
      subMenu: [
        {
          name: "Dolphin In Media",
          screen: "/explore-dolphin/media",
        },
        {
          name: "Latest Events",
          screen: "/explore-dolphin/blogs",
        },
        {
          name: "Publications",
          screen: "/explore-dolphin/publications",
        },
      ],
    },
    {
      title: "Careers",
      screen: "/careers",
    },
    // {
    //     title: "Contact us",
    //     screen: "/contact-us",
    // },
  ]);

  const [menuOpen, setMenuOpen] = useState(false);

  const onMenuItemClick = (e, menuItem) => {
    e.stopPropagation();

    let newMenu = [];

    menu?.forEach((item) => {
      if (item.title === menuItem.title) {
        newMenu.push({ ...item, active: !item.active });
      } else {
        newMenu.push({ ...item, active: false });
      }
    });

    setMenu(newMenu);
  };

  const goToScreen = (e, screen) => {
    e.stopPropagation();
    setMenuOpen(false);
    history.push(screen);
  };

  // customBurgerIcon = {< img width = { 40} height = { 40} src = "/images/icons/menu.png" />}
  return (
    <div className="mobile_navigation_container">
      {/* <div className="top_links">
                <span className="top_link" onClick={() => history.push("/careers")}>Careers</span>
            </div> */}

      {/* {!menuOpen && */}
      <div className="mobile_menu_nav_bar">
        <img
          className="mobile_logo"
          src="/images/logo.png"
          onClick={(e) => goToScreen(e, "/")}
        />
        <img
          className="mobile_menu"
          onClick={() => setMenuOpen(!menuOpen)}
          src="/images/icons/menu.png"
        />
      </div>
      {/* } */}

      <Menu
        overlayClassName={"mobile_menu_overlay"}
        itemListClassName={"mobile_side_menu_list"}
        isOpen={menuOpen}
        width={"100%"}
      >
        <div className="mobile_menu_nav_bar_cross">
          <img
            className="mobile_menu"
            onClick={() => setMenuOpen(!menuOpen)}
            src="/images/icons/cross.png"
          />
        </div>
        {menu.map((mainMenuItem, key) => {
          return mainMenuItem.subMenu ? (
            <div
              className="menu_item"
              onClick={(e) => goToScreen(e, mainMenuItem.screen)}
              key={key}
            >
              <div className="menu_item_title">
                <span className="">{mainMenuItem.title}</span>
                {mainMenuItem.active ? (
                  <img
                    src={"/images/icons/arrow-up.png"}
                    onClick={(e) => onMenuItemClick(e, mainMenuItem)}
                    alt="arrow"
                  />
                ) : (
                  <img
                    src={"/images/icons/arrow-down.png"}
                    onClick={(e) => onMenuItemClick(e, mainMenuItem)}
                    alt="arrow"
                  />
                )}
              </div>

              {mainMenuItem.active ? (
                <div className="sub_menu_item_list">
                  {mainMenuItem?.subMenu?.map((subMenuItem, key) => (
                    <div
                      key={key}
                      className="sub_menu_item"
                      onClick={(e) => goToScreen(e, subMenuItem.screen)}
                    >
                      {subMenuItem.name}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ) : (
            <div
              className="menu_item"
              onClick={(e) =>
                mainMenuItem.screen ? goToScreen(e, mainMenuItem.screen) : null
              }
              key={key}
            >
              <span className="">{mainMenuItem.title}</span>
            </div>
          );
        })}
      </Menu>
    </div>
  );
}

export default MobileMenu;
